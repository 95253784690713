import React from 'react';
import {connect} from "react-redux";
import {userUploadPhoto} from "../../actions";

class UploadPhoto extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {photoBeingUploaded: false, uploadedFile: null};
    }

    componentDidMount() {
        this._isMounted =  true;
    }

    uploadPicture = event => {
        this._isMounted && this.setState({uploadedFile: event.target.files[0], photoBeingUploaded: true}, () => {
            const data = new FormData();
            data.append('photo', this.state.uploadedFile);
            this.props.userUploadPhoto(this.props.user.id, data).then(() => {
                this._isMounted && this.setState({photoBeingUploaded: false})
                window.location.replace("/app");
            });
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderAddPhoto = () => {
        if (!this.state.photoBeingUploaded) {
            return (
                <div className="gallery-listItem">
                    <div className="gallery-listItem-inny">
                        <div
                            style={{background: 'white', border: '1px dashed grey',
                                borderRadius: '10px', height: '100%', width: '100%', display: 'flex',
                                justifyContent: 'center', alignItems: 'center', color: 'grey'}}
                        >
                            <label style={{cursor: 'pointer'}}>
                                <i className="icon-plane"/>
                                <span className="fwb">ADD PHOTO</span>
                                <input type="file" style={{visibility: 'hidden'}} onChange={this.uploadPicture}/>
                            </label>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="loader">Loading...</div>
            )
        }
    };

    render() {
        return (
            <div>
                <div className="popup-area-margin">
                    <div className="popup-block">
                        <div className="popup-content-wrapper popup-preferences">
                            <h1>Verification process</h1>
                            <div className="i-am-searching-for">
                                Hi, {this.props.user.name}<br/>
                                You need atleast one photo in your profile to begin verification process.
                            </div>
                            <div className="gallery">
                                <div className="gallery-list" style={{display: 'block', width: '50%'}}>
                                    {this.renderAddPhoto()}
                                </div>
                            </div>
                            <div style={{color: '#84939c', fontSize: '16px', marginTop: '35px'}}>
                                Once you upload at least one profile photo,<br/> to continue your verification
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    {userUploadPhoto}
)(UploadPhoto);