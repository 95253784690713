import React from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import {
    userHideFromGallery,
    userUnhideFromGallery,
    userRemoveProfile,
    getEmailNotifications,
    setEmailNotifications
} from "../../../actions";
import packageJson from '../../../../package.json';

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = { notificationsChecked: false };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.getEmailNotifications(this.props.userId).then(res => {
            this.setState({notificationsChecked: res.notificationsEmail});
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeNotifications = () => {
        let data = {notificationsEmail : !this.state.notificationsChecked};
        this.props.setEmailNotifications(this.props.userId, data);
        this._isMounted && this.setState({notificationsChecked: !this.state.notificationsChecked})
    };

    renderHideUnhideFromGalleryButton = () => {
        if (this.props.user.isHiddenFromGallery) {
            return(
                <Link to="#" className="settings-private--container" onClick={() => this.unhideFromGallery()}>
                    <span className="fwb tac w-100">Show me in the gallery</span>
                </Link>
            )
        } else {
            return(
                <Link to="#" className="settings-private--container" onClick={() => this.hideFromGallery()}>
                    <span className="fwb tac w-100">Temporarily hide from the gallery</span>
                </Link>
            )
        }
    };

    hideFromGallery = () => {
        this.props.userHideFromGallery(this.props.user.id);
    };

    unhideFromGallery = () => {
        this.props.userUnhideFromGallery(this.props.user.id);
    };

    deleteUserAccount = () => {
        this.props.userRemoveProfile(this.props.user.id);
    };

    render() {
        return(
            <div className="settings-private">
                <div style={{padding: '30px 0 5px 10px', fontSize: '14px', color: 'grey'}} className="fwb">
                    NOTIFICATIONS
                </div>
                <div className="settings-private--container last">
                    <div className="settings-private--container--item fwb">Email notifications</div>
                    <div className="settings-private--container--item" >
                        <input
                            checked={this.state.notificationsChecked}
                            className="apple-switch"
                            onChange={this.onChangeNotifications}
                            type="checkbox"
                        />
                    </div>
                </div>

                <div className="spacer-60"/>

                <Link to="/contact-support" className="settings-private--container last">
                    <span className="settings-private--container--item fwb">Contact support</span>
                    <span>
                        <i className="fas fa-chevron-right" style={{fontSize: '22px'}}/>
                    </span>
                </Link>

                <div className="spacer-60"/>

                <a href="/privacy-policy" className="settings-private--container">
                    <span className="settings-private--container--item fwb">Privacy policy</span>
                    <span>
                        <i className="fas fa-chevron-right" style={{fontSize: '22px'}}/>
                    </span>
                </a>
                <a href='/terms-and-conditions' className="settings-private--container last">
                    <span className="settings-private--container--item fwb">Terms and conditions</span>
                    <span>
                        <i className="fas fa-chevron-right" style={{fontSize: '22px'}}/>
                    </span>
                </a>

                <div className="spacer-60"/>

                {this.renderHideUnhideFromGalleryButton()}

                <Link to="#" className="settings-private--container last" onClick={() => {
                    if (window.confirm("Are you sure that you want to delete your profile?")) {
                        this.deleteUserAccount()
                    }
                }}>
                    <span className="fwb tac w-100">Delete account</span>
                </Link>

                <div className="app-info">
                    <img src="/app/images/logo.png" height="64" width="64" alt="Logo"/><br/>
                    ID : {this.props.user.id}<br/>
                    EMAIL : {this.props.user.email}<br/>
                    VERSION: {packageJson.version}
                </div>
        </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    {userHideFromGallery, userUnhideFromGallery, userRemoveProfile, getEmailNotifications, setEmailNotifications}
)(Settings);