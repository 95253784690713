import React from 'react';
import {connect} from "react-redux";
import {
    loggedUserData,
    userEditProfile,
    userSetMainPhoto,
    userDeletePhoto,
    userUploadPhoto
} from "../../../actions";
import {Link} from "react-router-dom";
import {GOOGLE_API_KEY} from "../../../config";
import Autocomplete from "react-google-autocomplete";

class EditProfile extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {
            loaded: false,
            name: "",
            location: "",
            birthday: "",
            wantsToVisit: [],
            about: "",
            selection: {},
            photoBeingUploaded: false,
            uploadedFile: null,
            rows: 10,
            minRows: 10,
            maxRows: 15,
        };
    }


    componentDidMount() {
        this._isMounted = true;
        this.props.loggedUserData(this.props.userId).then(() => this._isMounted && this.setState({
            loaded: true,
            name: this.props.user.name,
            location: (this.props.user.location) ? this.props.user.location.fullName : "",
            birthday: (this.props.user.birthday) ? this.props.user.birthday : "",
            about: (this.props.user.aboutMe) ? this.props.user.aboutMe : ""
        }));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderProfilePhotos = () => {
        return this.props.user.photos.map((photo, i) => {
            return (
                <div className="gallery-listItem" key={photo.id}>
                    <div className="gallery-listItem-inny">
                        <img src={photo.url} className="gallery-image" alt="Uploaded" />
                        <Link to="#" onClick={() => this.props.userDeletePhoto(this.props.user.id, photo.id, photo)}>
                            <div
                                style={{position: 'absolute', right: '-6px', bottom: '-6px', zIndex: '5', padding: '8px',
                                    borderRadius: '100px', background: 'white'}}
                            >
                                <i
                                    className="icon-delete"
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Link>
                        <Link to="#" onClick={() => this.props.userSetMainPhoto(this.props.user.id, photo.id, photo)}>
                            <div
                                style={{position: 'absolute', right: '-6px', top: '-6px', zIndex: '5', padding: '8px',
                                    borderRadius: '100px', background: 'white'}}
                            >
                                <i
                                    className="icon-settings"
                                    style={{cursor: 'pointer'}}
                                    title="Set main photo"
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            );
        })
    };

    onChangeUserInfo = event => {
        switch(event.target.id) {
            case 'name':
                this._isMounted && this.setState({name: event.target.value});
                break;
            case 'birthday':
                this._isMounted && this.setState({birthday: event.target.value});
                break;
            case 'about':
                const textareaLineHeight = 16;
                const { minRows, maxRows } = this.state;

                const previousRows = event.target.rows;
                event.target.rows = minRows; // reset number of rows in textarea

                const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

                if (currentRows === previousRows) {
                    event.target.rows = currentRows;
                }

                if (currentRows >= maxRows) {
                    event.target.rows = maxRows;
                    event.target.scrollTop = event.target.scrollHeight;
                }

                this._isMounted && this.setState({about: event.target.value, rows: currentRows < maxRows ? currentRows : maxRows});
                break;
            default:
                break;
        }
    };

    onLocationSelect = place => {
        this.setState({selection: place});
    };

    renderNavigationWorkaround() {
        return (
            <nav id="nav" className="navigation">
                <div className="nav-wrapper pointer-navigation">
                    <Link to='/profile/' style={{fontSize: '22px'}}>
                        <i className="icon-close"/>
                    </Link>

                    <span className="item-user" style={{justifyContent: 'center'}}>Edit profile</span>

                    <span className="fwb" style={{cursor: 'pointer'}} onClick={() => this.onClickSaveUserData()}>Save</span>
                </div>
            </nav>
        )
    }

    onClickSaveUserData = () => {
        if (this.state.loaded) {
            let data = {
                name: this.state.name,
                aboutMe: this.state.about,
                birthday: this.state.birthday,
            };
            if (this.state.selection.place_id) {
                data.placeId = this.state.selection.place_id;
            }
            this.props.userEditProfile(this.props.user.id, data).then(() => {
                alert("You information was saved");
            });
        }
    };

    renderAddPhoto = () => {
        return (
            <div className="gallery-listItem">
                <div className="gallery-listItem-inny">
                    <div
                        style={{background: 'white', border: '1px dashed grey',
                            borderRadius: '10px', height: '100%', width: '100%', display: 'flex',
                            textAlign: 'center', alignItems: 'center', color: 'grey', justifyContent: 'center'
                        }}
                    >
                        <label style={{cursor: 'pointer'}}>
                            <i className="icon-plane"/>
                            <span className="fwb">ADD PHOTO</span>
                            <input type="file" style={{visibility: 'hidden'}} onChange={this.uploadPicture}/>
                        </label>
                    </div>
                </div>
            </div>
        )
    };

    renderPhotoUploading = () => {
        if (this.state.photoBeingUploaded === true) {
            return (
                <div className="gallery-listItem">
                    <div className="gallery-listItem-inny">
                        <div className="loader">Loading...</div>
                    </div>
                </div>
            )
        }
    };

    uploadPicture = event => {
        this._isMounted && this.setState({uploadedFile: event.target.files[0], photoBeingUploaded: true}, () => {
            const data = new FormData();
            data.append('photo', this.state.uploadedFile);
            this.props.userUploadPhoto(this.props.user.id, data).then(() => {
                this._isMounted && this.setState({photoBeingUploaded: false})
            });
        });
    };

    render() {
        if (this.state.loaded) {
            return (
                <div>
                    {this.renderNavigationWorkaround()}
                    <div className="edit-profile">
                        <div className="gallery">
                            <div className="gallery-list">
                                {this.renderProfilePhotos()}
                                {this.renderPhotoUploading()}
                                {this.renderAddPhoto()}
                            </div>
                        </div>

                        <div className="field-group">
                            <label htmlFor="name" className="fwb">MY NAME</label><br/>
                            <input id="name" type="text" value={this.state.name} onChange={this.onChangeUserInfo}/>
                        </div>

                        <div className="field-group">
                            <label htmlFor="location" className="fwb">MY LOCATION</label><br/>
                            <Autocomplete
                                apiKey={GOOGLE_API_KEY}
                                onPlaceSelected={this.onLocationSelect}
                                placeholder={"Location"}
                                defaultValue={this.state.location}
                                options={{
                                    fields: ['address_components', 'geometry.location', 'geometry.viewport', 'place_id', 'formatted_address']
                                }}
                            />
                        </div>

                        <div className="field-group">
                            <label htmlFor="birthday" className="fwb">MY BIRTH DATE</label><br/>
                            <input id="birthday" type="date" value={this.state.birthday} onChange={this.onChangeUserInfo}/>
                        </div>

                        <div className="field-group">
                            <label htmlFor="about" className="fwb">ABOUT ME</label><br/>
                            <textarea id="about" type="text" rows={this.state.rows} value={this.state.about} onChange={this.onChangeUserInfo}/>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="loader">Loading...</div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    {
        loggedUserData,
        userEditProfile,
        userSetMainPhoto,
        userDeletePhoto,
        userUploadPhoto
    }
)(EditProfile);