import React from 'react';
import ReactPlayer from "react-player";
import { connect } from 'react-redux';
import { getGuestGallery } from "../../actions";
import InfiniteScroll from 'react-infinite-scroll-component';
import {loadMainPhotoHelper} from "../../reducers/photosHelper";

class GuestGallery extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {
            loaded: false,
            list: [],
            userPlayVideo: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.getGuestGallery().then(res => {
            res.users.map((user) => {
                const photo = loadMainPhotoHelper(user.photos, 200, 200);
                return user.photo = photo;
            });
            this.setState({loaded: true, list: res.users});
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderGalleryUsers() {
        return this.state.list.map((user) => {
            return (
                <div className="gallery-listItem" key={user.id}>
                    <div className="gallery-listItem-inny">
                        {this.renderPhotoOrVideo(user)}
                    </div>
                </div>
            );
        });
    };

    renderNavigationWorkaround() {
        return (
            <nav id="nav" className="navigation">
                <div className="nav-wrapper pointer-navigation">
                    <i
                        className="icon-menu"
                        style={{fontSize: '24px', cursor: 'pointer'}}
                        onClick={() => window.location.assign('/app/login')}
                    />

                    <div className="search-input" style={{position: 'relative'}}>
                    <span className="icon icon-search another-grey-text fs14" style={{marginRight: '6px'}}/>
                        <div className="search-input--area">
                            <input type="text"
                                   className="area-search-location"
                                   placeholder="Search for location"
                                   onClick={() => window.location.assign('/app/login')}
                                   autoComplete="off"
                            />
                        </div>
                    </div>

                    <i
                        className="icon-conversations"
                        style={{fontSize: '24px', position: 'relative', cursor: 'pointer'}}
                        onClick={() => window.location.assign('/app/login')}
                    />
                </div>
            </nav>
        );
    }

    renderPhotoOrVideo(user) {
        if (user.video && !user.video.isHidden) {
            if (this.state.userPlayVideo === user.id) {
                return (
                    <div className="gallery-video-container">
                        <div
                            className="gallery-name"
                            style={{
                                background: `url(${user.video.thumbnailUrl})`,
                                maxWidth: '100%',
                                width: 'auto',
                                backgroundSize: 'cover',
                                backgroundPositionY: '40%'
                            }}
                        >
                            <ReactPlayer
                                style={{zIndex: '100'}}
                                playing={true}
                                url={user.video.url}
                                className="gallery-name"
                                width={'unset'}
                                height={'unset'}
                                muted={true}
                                playsinline={true}
                                onEnded={() => {
                                    this._isMounted && this.setState({userPlayVideo: null})
                                }}
                                file={{
                                    file: {
                                        forceHLS: true
                                    }
                                }}
                            />
                        </div>
                    </div>
                )
            } else {
                return (
                    <div
                        className="gallery-video-container"
                        style={{cursor: 'pointer'}}
                        onClick={() => window.location.assign('/app/login')}
                    >
                        <img src={user.video.thumbnailUrl} className="gallery-image" alt={user.name} />
                        <div className="gallery-video-play-button">
                            <i className="fas fa-play-circle"/>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <img
                    alt={user.name}
                    src={user.photo}
                    className="gallery-image"
                    style={{cursor: 'pointer'}}
                    onClick={() => window.location.assign('/app/login')}
                />
            )
        }
    }

    render() {
        if (this.state.loaded && this._isMounted) {
            let _galleryClass = (this.state.list.length === 1) ? 'gallery-list-one' : 'gallery-list';
            return (
                <div className="gallery">
                    {this.renderNavigationWorkaround()}
                    <div className={{_galleryClass}}>
                        <InfiniteScroll
                            dataLength={this.state.list}
                            next={() => window.location.assign('/app/login')}
                            hasMore={true}
                            loader={<div className="loader">Loading...</div>}
                            className="gallery-infinite-scroll-fix"
                        >
                            {this.renderGalleryUsers()}
                        </InfiniteScroll>
                    </div>
                </div>
            );
        }

        return (
            <div className="loader">Loading...</div>
        )
    }
}
export default connect(
    null,
    { getGuestGallery }
)(GuestGallery);