export const isConversationPermissionGranted = (user, conversation) => {
    // Default values is false, that user cannot see the conversation
    let canReply = false;
    let subscription = false;

    // If conversation is not locked, it means that user had membership and can still communicate
    // with the participant in this conversation. We change canReply and return it
    if (!conversation.isLocked) {
        canReply = true;
        return canReply;
    }

    // Check user subscriptions, if has any, change that user can reply
    for (let key in user.subscriptions) {
        let sub = user.subscriptions[key];
        if (sub !== null && sub !== undefined) {
            subscription = true;
        }
    }

    // Check if male can reply. (Subscription has to be active or abletoReply true
    if ((subscription || user.ableToReply || !conversation.isLocked) && user.sex === 'male') {
        canReply = true;
        return canReply;
    // Check if sex is female, is she canBuyMembership and it is active -> return canReply true
    } else if (user.canBuyMembership && subscription && user.sex === 'female') {
        canReply = true;
        return canReply;
    // If female and her doesnt need to buy membership return that she can reply
    } else if (user.sex === 'female' && (!user.canBuyMembership || user.ableToReply)) {
        canReply = true;
        return canReply;
    } else if (!conversation.isLocked) {
        canReply = true;
        return canReply;
    } else {
        return canReply;
    }

};
