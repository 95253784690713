import React from 'react';
import {Link} from "react-router-dom";

export const NoMatchPage = () => {
    return (
        <div className="page-not-found">
            <h1 className="fwb">
                The page you were looking for was not found.
            </h1>

            <div style={{padding: '30px 0px 0px 0px'}}>
                <Link to="/">
                    Go back to the gallery
                </Link>
            </div>

            <div style={{padding: '30px 0px 0px 0px'}}>
                <Link to="/contact-support">
                    Contact Support
                </Link>
            </div>
        </div>
    );
};