import React from 'react';
import {connect} from 'react-redux';
import {userActivateRegistration} from "../actions";

class ProfileActivation extends React.Component {
    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        const uid = urlParams.get('uid');
        const code = urlParams.get('code');
        this.props.userActivateRegistration(uid, code);
    }

    render() {
        return <div className="loader">Loading...</div>
    }
}

export default connect(
    null,
    {userActivateRegistration}
)(ProfileActivation)