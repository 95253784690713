import React from 'react';
import { connect } from 'react-redux';
import { loggedUserData, logoutUser } from '../../actions';
import { Link } from 'react-router-dom';

//Settings
import MatchPreferences from './settings/MatchPreferences';
import Membership from './settings/Membership';
import PopPop from "react-poppop";

class Profile extends React.Component {
    state = {showUserPhoto: false, popupMembership: false};

    onLogoutClick = () => {
        this.props.logoutUser();
    };

    componentDidMount() {
        // if user is not loaded -> load it
        if (this.props.user === undefined) {
            this.props.loggedUserData(this.props.userId);
        }
    };

    closePopupMembership = () => {
        if (this.state.popupMembership) {
            this.setState({popupMembership: false})
        }
    };

    renderMembershipLink = () => {
        if (this.props.user.subscriptions.self !== null || this.props.user.subscriptions.both !== null) {
            return (
                <Link to="/membership">
                    <span className="icon"><i className="icon-membership" style={{fontSize:'26px'}}/></span>
                    <span className="text">Membership</span>
                </Link>
            )
        } else {
            return (
                <Link to="#" onClick={() => this.setState({popupMembership: true})}>
                    <span className="icon"><i className="icon-membership" style={{fontSize:'26px'}}/></span>
                    <span className="text">Membership</span>
                </Link>
            )
        }
    }

    render() {
        if (this.props.user.name) {
            return (
                <div>
                    <div className="settings--info-block">
                        <img
                            alt="My profile"
                            className="settings--info-block--img"
                            src={this.props.user.photo}
                            onClick={() => this.setState({showUserPhoto: true})}
                        />
                        <div className="settings--info-block--name">{this.props.user.name}</div>
                        <Link to="/edit-profile" className="settings--info-block--edit-button">EDIT PROFILE</Link>
                    </div>

                    <div>
                        <div className="settings--menu">
                            <MatchPreferences user={this.props.user} userId={this.props.userId}/>
                            <Link to="/likes">
                                <span className="icon"><i className="icon-heart"/></span>
                                <span className="text">My likes</span>
                            </Link>
                            {this.renderMembershipLink()}
                            <Link to="/settings">
                                <span className="icon"><i className="icon-settings"/></span>
                                <span className="text">Settings</span>
                            </Link>
                            <Link to="#" onClick={this.onLogoutClick}>
                                <span className="icon"><i className="icon-logout"/></span>
                                <span className="text">Logout</span>
                            </Link>
                        </div>
                    </div>

                    <PopPop
                        open={this.state.showUserPhoto}
                        position="centerCenter"
                        closeBtn={true}
                        contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                        onClose={() => this.setState({showUserPhoto: false})}
                    >
                        <img
                            alt="User"
                            src={(this.props.user.photos[0] !== undefined) ? this.props.user.photos[0].bigUrl : `/app/images/placeHolder.png`}
                            onClick={() => this.setState({showUserPhoto: false})}
                        />
                    </PopPop>

                    <Membership popupOpen={this.state.popupMembership} closePopupMembership={() => this.closePopupMembership()}/>
                </div>
            );
        }

        return (
            <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    {loggedUserData, logoutUser}
)(Profile);