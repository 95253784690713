import React from 'react';
import {CardElement, injectStripe, PaymentRequestButtonElement} from 'react-stripe-elements';

class PaymentRequestForm extends React.Component {
    constructor(props) {
        super(props);

        // Creates request to stripe, to determine if apple pay or any other service is available
        let paymentRequest;
        // Default country US is set by Justas, because stripe does not initiate without country
        let countryCode = (this.props.countryCode !== null) ? this.props.countryCode : 'US';
        const availableCountries = [
            'AE', 'AT', 'AU', 'BE', 'BG', 'BR', 'CA', 'CH', 'CI', 'CR', 'CY', 'CZ', 'DE', 'DK', 'DO', 'EE', 'ES', 'FI',
            'FR', 'GB', 'GR', 'GT', 'HK', 'HU', 'ID', 'IE', 'IN', 'IT', 'JP', 'LT', 'LU', 'LV', 'MT', 'MX', 'MY', 'NL',
            'NO', 'NZ', 'PE', 'PH', 'PL', 'PT', 'RO', 'SE', 'SG', 'SI', 'SK', 'SN', 'TH', 'TT', 'US', 'UY'
        ];
        if (!availableCountries.includes(countryCode)) {
            countryCode = 'US';
        }
        if (countryCode) {
            paymentRequest = props.stripe.paymentRequest({
                country: countryCode,
                currency: this.props.membership.currency.toLowerCase(),
                total: {
                    label: this.props.pricepointName,
                    amount: this.props.priceAmount * 100,
                },
            });

            // Creates token and sets it to parent component where the payment is handled
            paymentRequest.on('token', ({complete, token, ...data}) => {
                if (token.id) {
                    this.props.setToken(token.id)
                }
                // console.log('Received customer information: ', data);
                complete('success');
            });

            paymentRequest.canMakePayment().then((result) => {
                this.setState({canMakePayment: !!result});
            });
        }

        this.state = {
            canMakePayment: false,
            paymentRequest
        };
    }

    render() {
        return this.state.canMakePayment ? (
            <div>
                <PaymentRequestButtonElement
                    paymentRequest={this.state.paymentRequest}
                    className="PaymentRequestButton"
                    style={{
                        paymentRequestButton: {
                            theme: 'dark',
                            height: '64px',
                        },
                    }}
                />
                <CardElement hidePostalCode={true} style={{base: {fontSize: '18px'}}} />
            </div>
        ) : <CardElement hidePostalCode={true} style={{base: {fontSize: '18px'}}} />;
    }
}
export default injectStripe(PaymentRequestForm);