import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loadMainPhotoHelper, generatePhotoUrl } from "./photosHelper";
import { arrayUnique, aggregatePhotos, timeAgo, aggregateText, aggregateAbout } from "./customHelpers";

const storageUserId = localStorage.getItem('userId');
const storageUserToken = localStorage.getItem('userToken');
const INITIAL_LOGIN_STATE = {
    userId: (storageUserId) ? storageUserId : null,
    token: (storageUserToken) ? storageUserToken : null
};

const loginUser = (loginStatus = INITIAL_LOGIN_STATE, action) => {
    switch (action.type) {
        case 'LOGIN_USER':
            if (action.payload.status === 200) {
                localStorage.userId = action.payload.data.user.id;
                localStorage.userToken = action.payload.data.token;
                window.location.replace('/app');
                return {
                    ...INITIAL_LOGIN_STATE,
                    userId: action.payload.data.user.id,
                    token: action.payload.data.token
                };
            } else if (action.payload.status === 400) {
                alert(action.payload.data.error);
            }
            break;
        case 'LOGIN_FACEBOOK_USER':
            if (action.payload.status === 200) {
                localStorage.userId    = action.payload.data.user.id;
                localStorage.userToken = action.payload.data.token;
                window.location.replace('/app');
                return {
                    ...INITIAL_LOGIN_STATE,
                    userId: action.payload.data.user.id,
                    token: action.payload.data.token
                };
            }
            break;
        case 'USER_ACTIVATE_REGISTRATION':
            if (action.payload.status === 200) {
                localStorage.userId    = action.payload.data.user.id;
                localStorage.userToken = action.payload.data.token;
                window.location.replace('/app');
                return {
                    ...INITIAL_LOGIN_STATE,
                    userId: action.payload.data.user.id,
                    token: action.payload.data.token
                };
            }
            break;
        case 'LOGOUT_USER':
            localStorage.removeItem('userId');
            localStorage.removeItem('userToken');
            window.location.replace('/app');
            return {
                ...INITIAL_LOGIN_STATE,
                userId: null,
                token: null
            };
        case 'USER_DELETE_ACCOUNT':
            localStorage.removeItem('userId');
            localStorage.removeItem('userToken');
            window.location.replace('/app');
            return {
                ...INITIAL_LOGIN_STATE,
                userId: null,
                token: null
            };
        default:
            break;
    }

    return loginStatus;
};

const loggedUserData = (fetchedUser = {}, action) => {
    if (action.type === 'FETCH_USER') {
        let mainPhoto = loadMainPhotoHelper(action.payload.photos, 200, 200);
        let photos = aggregatePhotos(mainPhoto, action.payload.photos);
        return {...action.payload, photo: mainPhoto, photos};
    }
    if (action.type === 'USER_EDIT_PROFILE') {
        let mainPhoto = loadMainPhotoHelper(action.payload.photos, 200, 200);
        let photos = aggregatePhotos(mainPhoto, action.payload.photos);
        return {...action.payload, photo: mainPhoto, photos};
    }
    if (action.type === 'USER_ADD_NEW_PHOTO') {
        let uploadedPhoto = action.payload;
        let allPhotos = [...fetchedUser.photos, uploadedPhoto];
        let mainPhoto = loadMainPhotoHelper(allPhotos, 200, 200);
        let photos = aggregatePhotos(mainPhoto, allPhotos);
        return {...fetchedUser, photos};
    }
    if (action.type === 'USER_DELETE_PHOTO') {
        let filteredPhotos = fetchedUser.photos.filter(element => element !== action.payload.photo);
        let mainPhoto = loadMainPhotoHelper(filteredPhotos, 200, 200);
        let photos = aggregatePhotos(mainPhoto, filteredPhotos);
        return {...fetchedUser, photos};
    }
    if (action.type === 'USER_SET_MAIN_PHOTO') {
        let allPhotos = fetchedUser.photos;
        let mainPhoto = action.payload.photo;
        let photos = aggregatePhotos(mainPhoto, allPhotos);
        return {...fetchedUser, photos};
    }
    if (action.type === 'USER_HIDE_FROM_GALLERY') {
        let isHiddenFromGallery = action.payload.data.isHiddenFromGallery;
        return {...fetchedUser, isHiddenFromGallery}
    }
    if (action.type === 'USER_UNHIDE_FROM_GALLERY') {
        let isHiddenFromGallery = action.payload.data.isHiddenFromGallery;
        return {...fetchedUser, isHiddenFromGallery}
    }
    if (action.type === 'USER_SOCKET_UNREAD_CONVERSATION_ID') {
        let unreadConversationsIds = [...fetchedUser.unreadConversationsIds, action.payload];
        return {...fetchedUser, unreadConversationsIds};
    }
    if (action.type === 'MARK_CONVERSATION_AS_READ') {
        let unreadConversationsIds = fetchedUser.unreadConversationsIds;
        unreadConversationsIds = unreadConversationsIds.filter(element => element !== action.payload);
        return {...fetchedUser, unreadConversationsIds};
    }
    if (action.type === 'USER_VERIFICATION_STATUS' || action.type === 'USER_VERIFICATION_UPLOAD') {
        let verificationStatus = action.payload.status;
        if (verificationStatus !== 'verified' && verificationStatus !== 'waitingForUpload') {
            let verificationPhoto = generatePhotoUrl(action.payload.photo, action.payload.photo.crop, 600, null, 600);
            return {...fetchedUser, verificationStatus, verificationPhoto};
        } else {
            return {...fetchedUser, verificationStatus};
        }
    }
    return fetchedUser;
};

const galleryData = (gallery = {}, action) => {
    if (action.type === 'LOAD_GALLERY') {
        let users = [];
        if (gallery.users && action.payload.isSearch === 'gallery') {
            let userList = gallery.users;
            users = arrayUnique(userList.concat(action.payload.users));
        } else if (action.payload.isSearch === 'search') {
            users = action.payload.users;
        } else if (action.payload.isSearch === 'defaultGallery') {
            users = action.payload.users;
        } else {
            users = action.payload.users;
        }
        users.map((user) => {
            const photo = loadMainPhotoHelper(user.photos, 200, 200);
            return user.photo = photo;
        });
        let hasMore = action.payload.hasMore;
        let params = action.payload.params;
        let isSearch = action.payload.isSearch;
        return {...gallery, users, hasMore, params, isSearch};
    }
    return gallery;
};

const galleryUser = (user = {}, action) => {
    if (action.type === 'FETCH_GALLERY_USER') {
        const user = action.payload;
        let mainPhoto = loadMainPhotoHelper(user.photos, 200, 200);
        let photos = aggregatePhotos(mainPhoto, user.photos);
        let aboutMe = aggregateAbout(user.aboutMe);
        return {...user, photos, aboutMe}
    }
    if (action.type === 'SEND_USER_PRIVATE_MESSAGE') {
        if (action.payload.status === 201 && action.payload.statusText === "Created") {
            window.location.replace('/app/conversation/' + action.payload.data.sender + '/' + action.payload.data.id);
        }
    }
    return user;
};

const conversations = (conversations = {}, action) => {
    if (action.type === 'FETCH_CONVERSATIONS') {
        let conversationsList = [];
        if (conversations.conversationsList) {
            let loadedConversations = conversations.conversationsList;
            conversationsList = arrayUnique(loadedConversations.concat(action.payload.conversations));
        } else {
            conversationsList = action.payload.conversations;
        }
        let lastMessageId = action.payload.lastMessageId;
        conversationsList.map((conversation) => {
            conversation.lastMessage.timeago = timeAgo(conversation.lastMessage.sentAt);
            return conversation.user.photo = loadMainPhotoHelper(conversation.user.photos, 200, 200);
        });
        return {...conversations, conversationsList, lastMessageId};
    }

    if (action.type === 'USER_SOCKET_UNREAD_CONVERSATION') {
        let conversationsList = [];
        let updatedConvo = action.payload;
        updatedConvo.user.photo = loadMainPhotoHelper(updatedConvo.user.photos, 200, 200);
        updatedConvo.lastMessage.timeago = timeAgo(updatedConvo.lastMessage.sentAt);
        let loadedConversations = conversations.conversationsList;
        let lastMessageId = action.payload.lastMessage.id;
        if (conversations.conversationsList.length > 1) {
            for (let i = 0; i < loadedConversations.length; i++) {
                if (loadedConversations[i].id === updatedConvo.id) {
                    loadedConversations.splice(i, 1);
                    loadedConversations.unshift(action.payload);
                }
            }
            conversationsList = loadedConversations;
        } else if (conversations.conversationsList.length === 1) {
            conversationsList[0] = action.payload;
        } else {
            conversationsList[0] = action.payload;
        }
        return {...conversations, conversationsList, lastMessageId}
    }

    if (action.type === 'MARK_CONVERSATION_AS_READ') {
        let conversationsList = [];
        let loadedConversations = conversations.conversationsList;
        if (loadedConversations) {
            for (let i = 0; i < loadedConversations.length; i++) {
                if (loadedConversations[i].id === action.payload) {
                    loadedConversations[i].unread = false;
                }
            }
            conversationsList = loadedConversations;
        }
        return {...conversations, conversationsList}
    }


    return conversations;
};

const privateConversation = (conversation = {}, action) => {
    if (action.type === 'FETCH_PRIVATE_CONVERSATION') {
        const privateConvo = action.payload.conversation;
        privateConvo.user.photo = loadMainPhotoHelper(privateConvo.user.photos, 200, 200);
        action.payload.messages.map((msg) => {
            msg.content = aggregateText(msg.content);
            return msg.timeago = timeAgo(msg.sentAt);
        });
        // Reverse messages array to show latest at the bottom
        action.payload.messages.reverse();
        return action.payload;
    }

    if (action.type === 'FETCH_PRIVATE_CONVERSATION_LOAD_MORE') {
        let oldMessages = conversation.messages.reverse();
        let lastMessageId = action.payload.lastMessageId;
        let allMessages = [];
        let update = action.payload.messages;
        update.map((msg) => {
            msg.content = aggregateText(msg.content);
            return msg.timeago = timeAgo(msg.sentAt);
        });
        allMessages = arrayUnique(oldMessages.concat(update));
        allMessages.reverse();
        return {...conversation, messages: allMessages, lastMessageId};
    }

    if (action.type === 'SEND_USER_PRIVATE_MESSAGE') {
        const messages = (conversation.messages) ? conversation.messages : [];
        action.payload.timeago = timeAgo(action.payload.clientTime);
        action.payload.content = aggregateText(action.payload.content);
        action.payload.id = action.payload.clientTime / 1000;
        let update = [...messages, action.payload];
        return {...conversation, messages: update};
    }

    if (action.type === 'USER_SOCKET_NEW_MESSAGE') {
        const messages = (conversation.messages) ? conversation.messages : [];
        let newMessage = {};
        messages.map((msg) => {
            if (msg.clientTime === action.payload.clientTime) {
                newMessage = null;
            } else {
                if (newMessage === null || !newMessage.hasOwnProperty("id")) {
                    action.payload.content = aggregateText(action.payload.content);
                    action.payload.timeago = timeAgo(action.payload.sentAt);
                    action.payload.scroll = true;
                    newMessage = action.payload;
                }
            }
            return newMessage;
        });
        if (newMessage !== null) {
            let update = [...messages, newMessage];
            return {...conversation, messages: update};
        }
    }

    return conversation;
};

const userDataLikes = (likes = [], action) => {
    if (action.type === "USER_WHO_LIKED_ME") {
        action.payload.users.map((user) => {
            let mainPhoto = loadMainPhotoHelper(user.user.photos, 200, 200);
            return aggregatePhotos(mainPhoto, user.user.photos);
        });

        return [...action.payload.users];
    }
    return likes;
};

const userDataViews = (views = [], action) => {
    if (action.type === "USER_WHO_VIEWED_ME") {
        action.payload.list.map((user) => {
            return user.user.photo = loadMainPhotoHelper(user.user.photos, 200, 200);
        });
        return action.payload.list;
    }
    return views;
};

const userDataMyLikes = (likes = [], action) => {
    if (action.type === "USER_MY_LIKES") {
        action.payload.users.map((user) => {
            const photo = loadMainPhotoHelper(user.photos, 200, 200);
            return user.photo = photo;
        });
        return action.payload.users
    }
    return likes;
};

const userStripeSubscriptionList = (subscriptions = [], action) => {
    if (action.type === 'USER_STRIPE_SUBSCRIPTION_LIST') {
        return action.payload;
    }
    return subscriptions;
};

export default combineReducers({
    form: formReducer,
    user: loggedUserData,
    login: loginUser,
    gallery: galleryData,
    galleryUser: galleryUser,
    conversations: conversations,
    conversation: privateConversation,
    likes: userDataLikes,
    views: userDataViews,
    myLikes: userDataMyLikes,
    subscriptions: userStripeSubscriptionList
});