import React from 'react';
import { Link } from 'react-router-dom';

const AccountSuspended = () => {
    return (
        <div>
            <div className="popup-area-margin">
                <div className="popup-block">
                    <div className="popup-content-wrapper">
                        <div style={{marginTop: '50px', lineHeight: '50px', verticalAlign: 'middle', color: '#c4d2db', fontSize: '24px'}}>
                            - <i className="fas fa-ban"/> -
                        </div>
                        <div style={{fontSize: '22px', fontWeight: 'bold', marginBottom: '50px'}}>Your account is suspended!</div>
                        <div style={{padding: '0 20px'}}>
                            <Link to="/contact-support">
                                Contact Support
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AccountSuspended;