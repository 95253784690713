const imgUrl = 'https://e6ysple.cloudimg.io/';
const dpr = 1.5;
const placeHolder = '/app/images/placeholder.png';
export const loadMainPhotoHelper = (photosArray, givenWidth, givenHeight) => {
    return getMainPhoto(photosArray, givenWidth, givenHeight);
};

export const generatePhotoUrl = (photObject, crop, givenWidth, isOriginal, givenHeight) => {
    return generateClougImgPhotoUrl(photObject, crop, givenWidth, isOriginal, givenHeight);
};

const getMainPhoto = (photosArr, marginalWidth, marginalHeight) => {
    var mainPhoto = null;
    photosArr.map((p) => {
        if (typeof p.main !== 'undefined' && p.main === true) {
            mainPhoto = p;
        }
        return null;
    });
    if (!marginalHeight) {
        marginalHeight = marginalWidth;
    }

    return (mainPhoto !== null) ? generatePhotoUrl(mainPhoto, mainPhoto.crop, marginalWidth, null, marginalHeight) : placeHolder;
};

const generateClougImgPhotoUrl = (photObject, crop, givenWidth, isOriginal, givenHeight) => {
    var params = {
        w: photObject.width,
        h: photObject.height,
        angle: photObject.angle || 0,
        brightness: photObject.brightness || 0,
        contrast: photObject.contrast || 0,

        q: 80
    };

    //Angle is changed, because cloudimg.io doesn't handle it right
    if (params.angle !== 0) {
        params.angle = 360 - params.angle
    }

    //Brightness is changed, because cloudimg.io doesn't handle it right
    if (params.brightness < 0) {
        params.brightness = (params.brightness / 100) * 255
    } else if (params.brightness > 0) {
        params.brightness = (params.brightness / 100) * 140
    }

    if (isOriginal !== true) {
        if (params.angle === 90 || params.angle === 270) {
            let tmp = params.h;
            params.h = params.w;
            params.w = tmp;
        }
        // Longer image side should not be longer than 1000px
        if (params.h >= params.w && params.h > 1000) {
            delete params.w;
            params.h = 1000;
        } else if (params.h < params.w && params.w > 1000) {
            delete params.h;
            params.w = 1000;
        }
    } else {
        if (params.h >= params.w) {
            delete params.w;
        } else if (params.h < params.w ) {
            delete params.h;
        }
    }

    if (givenWidth && givenHeight) {
        params.w = givenWidth;
        params.h = givenHeight;
    } else if (givenWidth) {
        params.w = givenWidth;
        params.h = givenWidth;
    }

    // Adjust dpr to width and height (make sure that dpr wont increase original image width or height)
    //if (params.w && parseInt(params.w * this.dpr) <= photObject.width)  params.w = parseInt(params.w * this.dpr);
    //if (params.h && parseInt(params.h * this.dpr) <= photObject.height) params.h = parseInt(params.h * this.dpr);

    if (crop && givenWidth && givenHeight && givenHeight !== givenWidth) {
        var ratio = 1 - (params.h / params.w);
        var y1 = crop.y1;
        var y2 = crop.y2;
        var height = y2 - y1;
        var offset = height * ratio;
        var position = photObject.position;

        if (position === 'top') {
            crop.y2 = Math.max(0 ,Math.round(y2-offset));
        } else if (position === 'bottom') {
            crop.y1 = Math.max(0, Math.round(y1+offset));
        } else {
            crop.y1 = Math.max(0, Math.round(y1 + (offset/2)));
            crop.y2 = Math.max(0 ,Math.round(y2 - (offset/2)));
        }
    }

    // Generate params
    var urlParams = (crop && givenWidth)
        ? 'crop_px/'+crop.x1+','+crop.y1+','+crop.x2+','+crop.y2+'-'+ parseInt(givenWidth*dpr)
        : (params.w ? ('width/'+params.w) : ('height/'+params.h));

    // Generate options
    var urlOptions = '/r'+params.angle+'.fbright'+params.brightness+'.fcontrast'+params.contrast+'.i1.q'+params.q;
    return imgUrl + urlParams + urlOptions + '/_it_/' + photObject.path;
};