import React from 'react';
import { Link } from 'react-router-dom';
import inviteTravel from "../../../apis/inviteTravel";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";
import PopPop from 'react-poppop';

class MatchPreferences extends React.Component {
    state = {
        sex: [],
        isFemaleChecked: 0,
        isMaleChecked: 0,
        age: {from: 0, to: 0},
        popupOpen: false
    };

    componentDidMount() {
        if (this.state.sex.length === 0 && this.state.isFemaleChecked === 0 && this.state.isMaleChecked === 0) {
            this.setState({
                sex: this.props.user.matchPreferences.sex,
                isFemaleChecked: this.props.user.matchPreferences.sex.includes("female"),
                isMaleChecked: this.props.user.matchPreferences.sex.includes("male"),
                age: this.props.user.matchPreferences.age
            })
        }
    }

    renderFemaleChecbox = () => {
        return <input
            checked={this.state.isFemaleChecked}
            id="checkbox_female"
            type="checkbox"
            onChange={this.onFemaleCheckboxChange}
        />
    }

    renderMaleChecbox = () => {
        return <input
            checked={this.state.isMaleChecked}
            id="checkbox_male"
            type="checkbox"
            onChange={this.onMaleCheckboxChange}
        />
    }

    onSliderAgeChange = newAge => {
        this.setState({
            age: {
                from: newAge[0],
                to: newAge[1]
            }
        })
    }

    onFemaleCheckboxChange = () => {
        this.setState({isFemaleChecked: !this.state.isFemaleChecked})
    }

    onMaleCheckboxChange = () => {
        this.setState({isMaleChecked: !this.state.isMaleChecked})
    }

    saveMatchPreferences = () => {
        let sex = [];
        if (this.state.isFemaleChecked) {
            sex.push("female");
        }
        if (this.state.isMaleChecked) {
            sex.push("male");
        }
        if (sex.length === 0) {
            alert("You must select at least one sex to continue");
            return;
        }
        let data = {
            sex: sex,
            age: this.state.age
        };
        inviteTravel.post('/users/' + this.props.userId + '/match_preferences', data);
    }

    render() {
        return (
            <div>
                <Link to="#" onClick={() => this.setState({popupOpen: true})}>
                    <span className="icon"><i className="icon-search"/></span>
                    <span className="text">Search Preferences</span>
                </Link>
                <PopPop
                    open={this.state.popupOpen}
                    position="centerCenter"
                    closeBtn={true}
                    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                    onClose={() => this.setState({popupOpen: false})}
                >
                    <div className="popup-content-wrapper popup-preferences">
                        <h1>Search preferences</h1>
                        <div className="i-am-searching-for">I am searching for</div>

                        <form className="area-select-gender">
                            <div className="checkbox-container">
                                <img src="/app/images/search-female.png" alt="search-female" className="search-preferences-icons"/>
                                <div className="round">
                                    {this.renderFemaleChecbox()}
                                    <label htmlFor="checkbox_female"><span className="text">Girls</span></label>
                                </div>
                            </div>

                            <div className="checkbox-container">
                                <img src="/app/images/search-male.png" alt="search-female" className="search-preferences-icons"/>
                                <div className="round">
                                    {this.renderMaleChecbox()}
                                    <label htmlFor="checkbox_male"><span className="text">Men</span></label>
                                </div>
                            </div>
                        </form>

                        <div className="age-text">Age</div>
                        <div>
                            <Nouislider
                                range={{
                                    min: 18,
                                    '30%': 25,
                                    '70%': 45,
                                    max: 99,
                                }}
                                start={[this.state.age.from, this.state.age.to]}
                                connect
                                step={1}
                                tooltips={[true, true]}
                                format= {{
                                    to: function(value) {
                                        return Math.round(value);
                                    },
                                    from: function(value) {
                                        return Math.round(value);
                                    }
                                }}
                                className="age-slider"
                                style={{margin: '60px auto'}}
                                onChange={this.onSliderAgeChange}
                            />
                        </div>
                        <button
                            className="button-blue button-save action-save-preferences"
                            onClick={() => {
                                this.saveMatchPreferences();
                                this.setState({popupOpen: false})
                            }}
                        >
                            Save
                        </button>
                    </div>
                </PopPop>
            </div>
        )
    };
}

export default MatchPreferences;