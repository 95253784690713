import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';

import App from './components/App';
import reducers from './reducers';
import {BrowserRouter} from "react-router-dom";

const store = createStore(
    reducers,
    compose(applyMiddleware(reduxThunk))
);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter basename="/app/">
            <App/>
        </BrowserRouter>
    </Provider>,
document.querySelector("#App-content")
);