import React from 'react';
import {Route, withRouter, Switch} from "react-router-dom";
import { connect } from 'react-redux';
import { loginUser, loggedUserData, socketUnreadConversationIds } from "../actions";
import Gallery from './pages/Gallery';
import Conversations from './pages/Conversations';
import Conversation from './pages/Conversation';
import Profile from './pages/Profile';
import Login from './pages/loginForm';
import userProfile from './pages/userProfile'
import Header from './Header';
import MyLikes from './pages/settings/MyLikes';
import Settings from './pages/settings/Settings';
import contactSupport from "./pages/settings/contactSupport";
import EditProfile from "./pages/settings/EditProfile";
import AccountSuspended from "./AccountSuspended";
import Verification from "./Verification";
import UploadPhoto from "./onboarding/UploadPhoto";
import Subscriptions from "./pages/settings/Subscriptions";
import DiscountPopup from "./stripe/DiscountPopup"
import {NoMatchPage} from "./NoMatchPage";
import GuestGallery from "./pages/GuestGallery";
import ProfileActivation from "./ProfileActivation";

import './scss/main.scss';

// Onboarding menu
import Onboarding from "./onboarding/Onboarding";
import OnboardingPhoto from "./onboarding/OnboardingPhoto";

//Socket
import {socket} from "./Socket";
import Video from "./pages/Video";

// Helper to save users first visit
import Visit from "./pages/partials/Visit";

let scrollPositions = [];

class App extends React.Component {
    state = {loaded: null, location: '', popupOpenAndroid: false, popupOpeniOs: false, bookmarkClosed: false, isDiscount: false};

    componentDidMount() {
        // set local storage variables in order to not repeat the same popup

        // Kisuno prasymas isimt appsu popupus
        // 2020.03.10
        // TODO Grazinti
        /*if (localStorage.iosShown !== "true" && localStorage.androidShown !== "true") {
            localStorage.iosShown = "false";
            localStorage.androidShown = "false";
        }

        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            console.log("windows phone");
        }

        if (/android/i.test(userAgent)) {
            if (localStorage.androidShown === "false") {
                this.setState({popupOpenAndroid: true});
            }
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            if (localStorage.iosShown === "false") {
                this.setState({popupOpeniOs: true});
            }
        }
        */
        // end of checking if mobile;
        if (this.props.login.userId) {
            this.props.loggedUserData(this.props.login.userId).then(() => this.setState({loaded: true}));
        }

        // Socket for unreadConversations
        socket.emit('startListening', {"eventType":"unreadConversationId"});
        socket.on('unreadConversationId', (c) => {
            if (this.props.userData.unreadConversationsIds.indexOf(c.id) < 0) {
                try {
                    this.props.socketUnreadConversationIds(c.id);
                } catch (e) {

                }
            }
        });

        // Scroll Restoration
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual'
        }
        let { pathname } = this.props.location;
        if (scrollPositions[pathname]) {
            window.scrollTo(0, scrollPositions[pathname]);
        }
        window.addEventListener("scroll", this.listener);
    }

    componentDidUpdate(prevProps) {
        // Update state on every location change to trigger componentDidUpdate once again and scroll the page
        if (this.state.location !== this.props.location.pathname) {
            this.setState({location: this.props.location.pathname});
        }
        const { pathname } = this.props.location;
        if (!scrollPositions[pathname]) {
            // never seen this location before
            window.scrollTo(0, 0);
        } else {
            // seen it
            window.scrollTo(0, scrollPositions[pathname]);
        }
    }

    // Listener for scrollY
    listener = () => {
        scrollPositions[this.props.location.pathname] = window.scrollY;
    };

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listener);
    }

    appMenuHelper = () => {
        // Not logged user menu
        if (!this.props.login.userId && !this.props.login.token) {
            return (
                <Switch>
                    <Route path="/landing/gallery" exact component={GuestGallery} />
                    <Route path="/activation" component={ProfileActivation} />
                    {/*Stripe discount links*/}
                    <Route path="/offer=discount15" render={() => <Login discount="15" />} />
                    <Route path="/offer=discount20" render={() => <Login discount="20" />} />
                    <Route path="/offer=discount25" render={() => <Login discount="25" />} />
                    <Route path="/offer=discount30" render={() => <Login discount="30" />} />
                    {/*End Stripe discount links*/}
                    <Login/>
                    <Route component={NoMatchPage} />
                </Switch>
                );
            // Account suspended
        } else if (this.state.loaded && this.props.userData.view.accountSuspended) {
            return (
                <Switch>
                    <Route path="/contact-support" component={contactSupport} />
                    <Route path="/" exact component={AccountSuspended} />
                    <Route component={NoMatchPage} />
                </Switch>
            )
            // Needs to verify && photos.length === 0
        } else if (this.state.loaded && this.props.userData.needsToVerify && this.props.userData.photos.length === 0) {
            return <Route path="/" component={UploadPhoto} />
            // Has photos but needs to verify
        } else if (this.state.loaded && this.props.userData.needsToVerify) {
            return (
                <Switch>
                    <Route path="/" exact component={Verification} />
                    <Route path="/contact-support" component={contactSupport} />
                    <Route component={NoMatchPage} />
                </Switch>
                )
            // No age or sex is === null
        } else if (this.state.loaded && (this.props.userData.age === null || this.props.userData.sex === null)) {
            return <Route path="/" exact component={Onboarding} />
            // Loaded but skipped uploading a photo
        } else if (this.state.loaded && this.props.userData.photos.length === 0) {
            return (
                    <div>
                        <nav id="nav" className="navigation">
                            <Header
                                pathHelper={this.props}
                                stateForDiscountPage={() => this.stateForDiscountPage()}
                                removeStateForDiscountPage={() => this.removeStateForDiscountPage()}
                            />
                        </nav>
                        <Switch>
                            <Route path="/" exact component={Gallery} />
                            <Route path="/conversations" component={Conversations} />
                            <Route path="/onboarding-photo" component={OnboardingPhoto} />
                            <Route path="/profile" component={Profile} />
                            <Route path="/login" component={Login} />
                            <Route path="/gallery/:participantId" component={OnboardingPhoto}/>
                            <Route path="/conversation/:participantId" component={Conversation}/>
                            <Route path="/likes" component={MyLikes} />
                            <Route path="/settings" component={Settings} />
                            <Route path="/contact-support" component={contactSupport} />
                            <Route path="/edit-profile" component={EditProfile} />
                            <Route path="/membership" component={Subscriptions} />
                            <Route path="/video" component={Video} />


                            {/*Stripe discount links*/}
                                <Route path="/offer=discount15" render={() => <DiscountPopup discount="15" />} />
                                <Route path="/offer=discount20" render={() => <DiscountPopup discount="20" />} />
                                <Route path="/offer=discount25" render={() => <DiscountPopup discount="25" />} />
                                <Route path="/offer=discount30" render={() => <DiscountPopup discount="30" />} />
                            {/*End Stripe discount links*/}

                            <Route component={NoMatchPage} />
                        </Switch>
                    </div>
            );
            // User is OK
        } else if(this.state.loaded) {
            return (
                <div>
                    <nav id="nav" className="navigation">
                        <Header
                            pathHelper={this.props}
                            stateForDiscountPage={() => this.stateForDiscountPage()}
                            removeStateForDiscountPage={() => this.removeStateForDiscountPage()}
                        />
                    </nav>
                    <Switch>
                        <Route path="/" exact component={Gallery} />
                        <Route path="/conversations" component={Conversations} />
                        <Route path="/profile" component={Profile} />
                        <Route path="/login" component={Login} />
                        <Route path="/gallery/:participantId" component={userProfile}/>
                        <Route path="/conversation/:participantId" component={Conversation}/>
                        <Route path="/likes" component={MyLikes} />
                        <Route path="/settings" component={Settings} />
                        <Route path="/contact-support" component={contactSupport} />
                        <Route path="/edit-profile" component={EditProfile} />
                        <Route path="/membership" component={Subscriptions} />
                        <Route path="/video" component={Video} />

                        {/*Stripe discount links*/}
                            <Route path="/offer=discount15" render={() => <DiscountPopup discount="15" />} />
                            <Route path="/offer=discount20" render={() => <DiscountPopup discount="20" />} />
                            <Route path="/offer=discount25" render={() => <DiscountPopup discount="25" />} />
                            <Route path="/offer=discount30" render={() => <DiscountPopup discount="30" />} />
                        {/*End Stripe discount links*/}

                        <Route component={NoMatchPage} />
                    </Switch>
                </div>
            );
        }
    };

    stateForDiscountPage = () => {
        if (!this.state.isDiscount) {
            this.setState({isDiscount: true});
        }
    };

    removeStateForDiscountPage = () => {
        if (this.state.isDiscount) {
            this.setState({isDiscount: false});
        }
    };

    render() {
        return (
            <div>
                <Visit/>
                {(this.state.isDiscount) ? <div style={{marginTop: '60px', paddingBottom: '5px'}}/> : ''}
                {this.appMenuHelper()}
                <div id="App-padd"/>

                {/*<PopPop*/}
                {/*    open={this.state.popupOpenAndroid}*/}
                {/*    position="centerCenter"*/}
                {/*    closeBtn={true}*/}
                {/*    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}*/}
                {/*    onClose={() => {*/}
                {/*        this.setState({popupOpenAndroid: false});*/}
                {/*        localStorage.androidShown = "true";*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div className="popup-content-wrapper popup-preferences fwb">*/}
                {/*        <h1>Download our app!</h1>*/}
                {/*    </div>*/}
                {/*    <a className="flexibleLink" href="https://app.adjust.com/seq498" aria-label="Download our to your mobile device!">*/}
                {/*        <div className="android-image"/>*/}
                {/*    </a>*/}
                {/*</PopPop>*/}

                {/*<PopPop*/}
                {/*    open={this.state.popupOpeniOs}*/}
                {/*    position="centerCenter"*/}
                {/*    closeBtn={true}*/}
                {/*    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}*/}
                {/*    onClose={() => {*/}
                {/*        this.setState({popupOpeniOs: false});*/}
                {/*        localStorage.iosShown = "true";*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <div className="popup-content-wrapper popup-preferences fwb">*/}
                {/*        <h1>Download our app!</h1>*/}
                {/*    </div>*/}
                {/*    <a className="flexibleLink" href="https://app.adjust.com/seq498" aria-label="Download our to your mobile device!">*/}
                {/*        <div className="appStore-image"/>*/}
                {/*    </a>*/}
                {/*</PopPop>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        userData: state.user
    }
};


export default withRouter(connect(
    mapStateToProps,
    {loginUser, loggedUserData, socketUnreadConversationIds}
)(App));