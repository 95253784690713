import React from 'react';
import ReactPlayer from "react-player";
import { connect } from 'react-redux';
import { galleryData, userUnhideFromGallery } from "../../actions";
import { Link } from "react-router-dom";

import InfiniteScroll from 'react-infinite-scroll-component';

class Gallery extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {
            loaded: false,
            isHiddenFromGallery: null,
            userPlayVideo: null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.gallery !== undefined) {
            this._isMounted && this.setState({loaded: true});
        } else {
            this.props.galleryData(this.props.userId, 'gallery').then(() => this._isMounted && this.setState({loaded: true}));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderHeartForLikedUsers(user) {
        if (user.likes.like) {
            return (
                <div className={`gallery-fav icon`}>
                    <i className="fas fa-heart" />
                </div>
            );
        }
    }

    renderGalleryUsers() {
        return this.props.gallery.map((user) => {
            // const isFavorited = (this.props.user.isFavorited) ? 'icon-star' : '';
            return (
                <div className="gallery-listItem" key={user.id}>
                    <div className="gallery-listItem-inny">
                        {this.renderHeartForLikedUsers(user)}
                        {this.renderPhotoOrVideo(user)}
                    </div>
                </div>
            );
        });
    };

    renderPhotoOrVideo(user) {
        if (user.video && !user.video.isHidden) {
            if (this.state.userPlayVideo === user.id) {
                return (
                    <div className="gallery-video-container">
                        <Link to={`gallery/${user.id}`}>
                            <div
                                className="gallery-name"
                                style={{
                                    background: `url(${user.video.thumbnailUrl})`,
                                    maxWidth: '100%',
                                    width: 'auto',
                                    backgroundSize: 'cover',
                                    backgroundPositionY: '40%'
                                }}
                            >
                                <ReactPlayer
                                    style={{zIndex: '100'}}
                                    playing={true}
                                    url={user.video.url}
                                    width={'unset'}
                                    height={'unset'}
                                    muted={true}
                                    playsinline={true}
                                    onEnded={() => {
                                        this._isMounted && this.setState({userPlayVideo: null})
                                    }}
                                    file={{
                                        file: {
                                            forceHLS: true
                                        }
                                    }}
                                />
                            </div>
                        </Link>
                    </div>
                )
            } else {
                return (
                    <div className="gallery-video-container">
                        <Link to={`gallery/${user.id}`}>
                            <img src={user.video.thumbnailUrl} className="gallery-image" alt={user.name} />
                        </Link>
                        <div className="gallery-video-play-button">
                            <i className="fas fa-play-circle" onClick={() => {
                                this._isMounted && this.setState({userPlayVideo: user.id});
                            }}/>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <Link to={`gallery/${user.id}`}>
                    <img src={user.photo} className="gallery-image" alt={user.name} />
                </Link>
            )
        }
    }

    galleryLoadMore = () => {
        let offset = this.props.galleryParams.offset;
        let newOffset = offset + 20;
        this.props.galleryData(this.props.userId, 'gallery', newOffset);
    };

    unhideFromGallery = () => {
        this.props.userUnhideFromGallery(this.props.userId);
    };

    render() {
        if (this.props.user.isHiddenFromGallery === true) {
            return (
                <button className="button-blue button-save" onClick={() => this.unhideFromGallery()}>
                    You have hidden your card from the gallery. Click to unhide!
                </button>
            );
        }

        if (this.state.loaded && this._isMounted) {
            let _galleryClass = (this.props.gallery.length === 1) ? 'gallery-list-one' : 'gallery-list';
            return (
                <div className="gallery">
                    <div className={{_galleryClass}}>
                        <InfiniteScroll
                            dataLength={this.props.gallery}
                            next={this.galleryLoadMore}
                            hasMore={this.props.galleryHasMore}
                            loader={<div className="loader">Loading...</div>}
                            className="gallery-infinite-scroll-fix"
                        >
                            {this.renderGalleryUsers()}
                        </InfiniteScroll>
                    </div>
                </div>
            );
        }

        return (
            <div className="loader">Loading...</div>
        )
    }
}

const mapStateToProps = state => {
    return {
        gallery: state.gallery.users,
        galleryHasMore: state.gallery.hasMore,
        galleryParams: state.gallery.params,
        gallerySearchType: state.gallery.isSearch,
        userId: state.login.userId,
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    { galleryData, userUnhideFromGallery }
)(Gallery);