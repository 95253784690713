import React from 'react';
import { connect } from 'react-redux';
import { fetchConversations, loggedUserData, socketConversation, markConversationAsRead } from "../../actions";
import { Link } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

//Socket
import {socket} from "../Socket";
// Conversations Components
import Likes from './conversations/Likes';
import Views from './conversations/Views';
import {isConversationPermissionGranted} from './conversations/isConversationPermissionGranted';
import ConversationsMembershipPopup from "./conversations/ConversationsMembershipPopup";

class Conversations extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {loaded: false, popupOpen: false, user: {}, paymentPopupOpen: false};
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.conversations !== undefined) {
            this._isMounted && this.setState({loaded: true});
        } else {
            console.log("test");
            this.props.fetchConversations(this.props.login.userId)
                .then(() => this._isMounted && this.setState({loaded: true}));
        }
        try {
            socket.emit('startListening', {"eventType":"unreadConversation"});
            socket.on('unreadConversation', (c) => {
                this.props.socketConversation(c);
            });   
        } catch (e) {

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderConversations() {
        return this.props.conversations.map((convo,i) => {
            const isUnreadImg = (convo.unread) ? 'border-unread' : '';
            const isUnreadName = (convo.unread) ? 'fwb' : '';
            const isUnreadContent = (convo.unread) ? 'content-unread' : '';
            let data = {userId: this.props.login.userId, participantId: convo.id};

            // Returns true/false if user can see the converstion
            let userCanSeeConversation = isConversationPermissionGranted(this.props.user, convo);
            if (userCanSeeConversation) {
                return (
                    <div key={i}>
                        { i === 3 ? <Views userId={this.props.login.userId} user={this.props.user}/> : '' }
                        <Link to={`/conversation/${convo.id}`} onClick={() => this.props.markConversationAsRead(data)}>
                            <div className="conversations--list--item">
                                <div className="conversations--list--item--photo">
                                    <img src={convo.user.photo} className={`conversations--list--item--photo--img ${isUnreadImg}`} alt="Participant" />
                                </div>

                                <div className={`conversations--list--item--name ${isUnreadName}`}>{convo.user.name}</div>
                                <div className={`conversations--list--item--content ${isUnreadContent}`}>
                                    {convo.lastMessage.content}
                                </div>
                                <div className="time">{convo.lastMessage.timeago}</div>

                                {/*<div className="conversations--list--item--delete-area">Delete</div>*/}
                            </div>
                        </Link>
                    </div>

                );
            } else {
                return (
                    <div key={i}>
                        { i === 3 ? <Views userId={this.props.login.userId} user={this.props.user}/> : '' }
                        <Link to="#" onClick={() => this._isMounted && this.setState({popupOpen: true, user: convo.user})}>
                            <div className="conversations--list--item">
                                <div className="conversations--list--item--photo">
                                    <img src={convo.user.photo} className={`conversations--list--item--photo--img ${isUnreadImg}`} alt="Participant" />
                                </div>

                                <div className={`conversations--list--item--name ${isUnreadName}`}>{convo.user.name}</div>
                                <div className={`conversations--list--item--content ${isUnreadContent}`}>
                                    <span className="conversations--unread-title">New Message</span>
                                </div>
                                <div className="time">{convo.lastMessage.timeago}</div>

                                {/*<div className="conversations--list--item--delete-area">Delete</div>*/}
                            </div>
                        </Link>
                    </div>

                );
            }
        });
    }

    renderUnreadCounter() {
        let unreadCount = 0;
        if (this.props.conversations) {
            this.props.conversations.map((c) => {
                if (c.unread) {
                    unreadCount = unreadCount+1;
                }
                return unreadCount;
            });
            if (unreadCount > 0) {
                return <div className="conversations--unread-title">New <span className="fwb unread-counter">{unreadCount}</span></div>
            }
        }
    }

    conversationsLoadMore = () => {
        let lastMsgId = (this.props.lastMessageId !== null) ? this.props.lastMessageId : null;
        this.props.fetchConversations(this.props.login.userId, lastMsgId);
    };

    closeMembershipPopup = () => {
        if (this.state.popupOpen) {
            this.setState({popupOpen: false});
        }
    };

    render() {
        if (this.state.loaded && this.props.conversations.length > 0) {
            return (
                <div className="conversations">
                    <div className="conversations-area">
                            <Likes userId={this.props.login.userId}/>
                        <div className="conversations--header">
                            <div className="conversations--title">Messages</div>
                            {this.renderUnreadCounter()}
                        </div>
                        <div className="conversations--list">
                            <InfiniteScroll
                                dataLength={this.props.conversations.length}
                                next={this.conversationsLoadMore}
                                hasMore={this.props.lastMessageId !== null}
                            >
                                {this.renderConversations()}
                            </InfiniteScroll>
                        </div>
                    </div>
                        <ConversationsMembershipPopup
                            conversation={this.props.conversation}
                            user={this.props.user}
                            participant={this.state.user}
                            requiresClosePopup={true}
                            closeMembershipPopup={() => this.closeMembershipPopup()}
                            popupOpen={this.state.popupOpen}
                            fromConversation={false}
                        />
                </div>
            );
        } else if (this.state.loaded && this.props.conversations.length === 0) {
            return(
                <div className="conversations">
                    <div className="tac" style={{marginTop: '15px', color: '#84939c'}}>
                        Your inbox is empty
                    </div>
                </div>
            )
        }

        return (
            <div className="loader">Loading...</div>
        );
    }
}

const mapStateToProps = state => {
    return {
        login: state.login,
        conversations: state.conversations.conversationsList,
        lastMessageId: state.conversations.lastMessageId,
        user: state.user,
    }
};

export default connect(
    mapStateToProps,
    {fetchConversations, loggedUserData, socketConversation, markConversationAsRead}
)(Conversations);