import React from 'react';
import {connect} from "react-redux";
import {loggedUserData, userEditProfileOnboarding} from "../../actions";
import {Link} from "react-router-dom";
import Nouislider from "nouislider-react";

class Onboarding extends React.Component {
    state = {
        age: 18,
        isFemaleChecked: 0,
        isMaleChecked: 0,

    };

    renderFemaleChecbox = () => {
        return <input
            checked={this.state.isFemaleChecked}
            id="checkbox_female"
            type="checkbox"
            onChange={this.onFemaleCheckboxChange}
        />
    };

    renderMaleChecbox = () => {
        return <input
            checked={this.state.isMaleChecked}
            id="checkbox_male"
            type="checkbox"
            onChange={this.onMaleCheckboxChange}
        />
    };

    onFemaleCheckboxChange = () => {
        this.setState({
            isMaleChecked: false,
            isFemaleChecked: true
        })
    };

    onMaleCheckboxChange = () => {
        this.setState({
            isMaleChecked: true,
            isFemaleChecked: false
        })
    };

    onSliderAgeChange = newAge => {
        this.setState({age: newAge[0]})
    };

    onClickSaveAgeAndGender = () => {
        if ((this.state.isFemaleChecked || this.state.isMaleChecked) && this.state.age >= 18) {
            let data = {
                birthday: this.aggregateBirthday(this.state.age),
                sex: (this.state.isFemaleChecked) ? "female" : "male"
            };
            this.props.userEditProfileOnboarding(this.props.user.id, data);
        }
    };

    aggregateBirthday = age => {
        const date = new Date();
        date.setDate( date.getDate() - 1);
        date.setFullYear(date.getFullYear() - age);

        const currentMonth = date.getMonth()+1;
        const dd = (date.getDate() < 10) ? '0' + date.getDate() : date.getDate();
        const mm = (currentMonth < 10) ? '0' + currentMonth : currentMonth;
        return date.getFullYear() + '-' + mm + '-' + dd;
    };

    render() {
            return (
                <div>
                    <div className="popup-area-margin">
                        <div className="popup-block">
                            <div className="popup-content-wrapper popup-preferences">
                                <h1>Finish your registration</h1>
                                <div className="i-am-searching-for">Please select your age and gender</div>
                                <form className="area-select-gender">
                                    <div className="checkbox-container">
                                        <div className="round">
                                            {this.renderFemaleChecbox()}
                                            <label htmlFor="checkbox_female"><span className="text">Female</span></label>
                                        </div>
                                    </div>

                                    <div className="checkbox-container">
                                        <div className="round">
                                            {this.renderMaleChecbox()}
                                            <label htmlFor="checkbox_male"><span className="text">Male</span></label>
                                        </div>
                                    </div>
                                </form>

                                <div className="age-text">Age</div>
                                <div>
                                    <Nouislider
                                        range={{
                                            min: 18,
                                            '30%': 25,
                                            '70%': 45,
                                            max: 99,
                                        }}
                                        start={[18]}
                                        step={1}
                                        tooltips={[true]}
                                        format= {{
                                            to: function(value) {
                                                return Math.round(value);
                                            },
                                            from: function(value) {
                                                return Math.round(value);
                                            }
                                        }}
                                        className="age-slider"
                                        style={{margin: '60px auto'}}
                                        onChange={this.onSliderAgeChange}
                                    />
                                </div>
                                {((this.state.isFemaleChecked || this.state.isMaleChecked) && this.state.age >= 18) ?
                                    <Link
                                        className="button-blue button-save action-save-preferences"
                                        onClick={() => {this.onClickSaveAgeAndGender();}}
                                        to="/onboarding-photo"
                                    >
                                        Continue
                                    </Link>
                                    :
                                    <button
                                        className="button-grey button-save"
                                        disabled
                                    >
                                        Continue
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    {loggedUserData, userEditProfileOnboarding}
)(Onboarding);