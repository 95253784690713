import React from 'react';
import { connect } from 'react-redux';
import { loggedUserData, uploadVideo, generateMuxUpload } from '../../actions';
import {siteConfig} from "../../config";

const UpChunk = require('@mux/upchunk');

class Video extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {
            name: "",
            location: "",
            holderName: '',
            holderAddress: '',
            accountNo: '',
            swift: '',
            bankAddress: '',
            fileName: [],
            agreedTerms: false,
            uploading: false,
            videoIds: [],
            filesCount: 0
        };
    }

    componentDidMount() {
        this._isMounted = true;
        // if user is not loaded -> load it
        if (this.props.user === undefined) {
            this.props.loggedUserData(this.props.userId);
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.fileName.length === this.state.filesCount) {
            if (this.state.uploading) {
                this.setState({uploading: false});
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLocationChange = event => {
        this._isMounted && this.setState({location: event.target.value});
    };

    changeName = event => {
        this._isMounted && this.setState({name: event.target.value});
    };

    renderBankDetailsInputs() {
        return (
            <div>
                <div className="field-group">
                    <label htmlFor="holder-name" className="fwb">Bank account holder name</label><br/>
                    <input
                        id="holder-name"
                        type="text"
                        value={this.state.holderName}
                        onChange={this.onBankChange}
                        autoComplete="off"
                    />
                </div>
                <div className="field-group">
                    <label htmlFor="holder-address" className="fwb">Bank account holder address</label><br/>
                    <input
                        id="holder-address"
                        type="text"
                        value={this.state.holderAddress}
                        onChange={this.onBankChange}
                        autoComplete="off"
                    />
                </div>
                <div className="field-group">
                    <label htmlFor="account-no" className="fwb">Bank account No.</label><br/>
                    <input
                        id="account-no"
                        type="text"
                        value={this.state.accountNo}
                        onChange={this.onBankChange}
                        autoComplete="off"
                    />
                </div>
                <div className="field-group">
                    <label htmlFor="swift" className="fwb">Bank SWIFT/BIC</label><br/>
                    <input
                        id="swift"
                        type="text"
                        value={this.state.swift}
                        onChange={this.onBankChange}
                        autoComplete="off"
                    />
                </div>
                <div className="field-group">
                    <label htmlFor="bank-address" className="fwb">Bank address</label><br/>
                    <input
                        id="bank-address"
                        type="text"
                        value={this.state.bankAddress}
                        onChange={this.onBankChange}
                        autoComplete="off"
                    />
                </div>
            </div>
        )
    }

    onBankChange = event => {
        switch(event.target.id) {
            case 'holder-name':
                this._isMounted && this.setState({holderName: event.target.value});
                break;
            case 'holder-address':
                this._isMounted && this.setState({holderAddress: event.target.value});
                break;
            case 'account-no':
                this._isMounted && this.setState({accountNo: event.target.value});
                break;
            case 'swift':
                this._isMounted && this.setState({swift: event.target.value});
                break;
            case 'bank-address':
                this._isMounted && this.setState({bankAddress: event.target.value});
                break;
            default:
                break;
        }
    };

    submitForm = (data) => {
        let isFormValid = this.validateForm(data);
        if (isFormValid) {
            this.props.uploadVideo(this.props.userId, data).then(() => {
                // Restore state after uploading
                this._isMounted && this.setState({
                    name: "",
                    location: "",
                    holderName: '',
                    holderAddress: '',
                    accountNo: '',
                    swift: '',
                    bankAddress: '',
                    fileName: [],
                    agreedTerms: false,
                    upload: false,
                    videoIds: [],
                    filesCount: 0
                })
            });
        } else {
            alert("All fields are required!");
        }
    };

    validateForm = (data) => {
        let emptyKeys = [];
        Object.entries(data).forEach(([key,value])=>{
            if (value.length === 0 && key !== 'filesCount') {
                emptyKeys.push(key);
            }
        });
        return emptyKeys.length === 0;
    };

    onChangeFiles = (e) => {
        let length = Object.keys(e.target.files).length;
        if (!this.state.uploading) {
            this.setState({uploading: true, filesCount: length});
        }
        let i = 0;
        while (i !== length) {
            let file = e.target.files[i];
            this.props.generateMuxUpload(this.props.userId).then(res => {
                let id = res.data.id;
                this.setState({videoIds: [...this.state.videoIds, id]});
                const upload = UpChunk.createUpload({
                    file,
                    endpoint: res.data.url
                });
                upload.on('success', () => {
                    this.setState({fileName: [...this.state.fileName, file.name]});
                });
            });
            i += 1;
        }
    };

    renderUploadButton() {
        if (this.state.fileName.length === 0) {
            return (
                <div>
                    <label htmlFor="name" className="fwb">Video</label><br/>
                    {(this.state.uploading) ? <div className="loader-small">Loading...</div> : ''}
                    <input type="file" accept="video/*" onChange={this.onChangeFiles} multiple disabled={this.state.uploading}/>
                </div>
            )
        } else {
            return (
                <div>
                    <label htmlFor="name" className="fwb">Video</label><br/>
                    {(this.state.uploading) ? <div className="loader-small">Loading...</div> : ''}
                    <span className="fwb">Successfully uploaded {(this.state.fileName.length)} videos</span><br/>
                    {
                        this.state.fileName.map(name => {
                            return <div className="filename" key={name}><small>({name})</small><br/></div>
                        })
                    }
                </div>
            )
        }
    }

    buildLinkForPdf = () => {
        let filename = (this.state.fileName.length === 1) ? this.state.fileName[0] : this.state.fileName.join(", ");
        let token = (localStorage.getItem('userToken')) ? localStorage.getItem('userToken') : '';
        return siteConfig.apiURL +
          'pdf/' + this.props.userId + '/generate_commercial_pdf' +
          '/?name=' + this.state.name +
          '&location=' + this.state.location +
          '&holderName=' + this.state.holderName +
          '&holderAddress=' + this.state.holderAddress +
          '&accountNo=' + this.state.accountNo +
          '&swift=' + this.state.swift +
          '&bankAddress=' + this.state.bankAddress +
          '&filename=' + filename +
          '&token=' + token +
          '&show=html';
    };

    render() {
        if (this.props.user.name) {
            let isFormValid = (this.validateForm(this.state) && this.state.uploading === false) ? '' : 'hidden';
            let isCheckbox = this.state.agreedTerms ? '' : 'hidden';
            return (
                <div className="video-submit-form">
                    <div className="field-group">
                        <label htmlFor="name" className="fwb">My name</label><br/>
                        <input id="name" type="text" value={this.state.name} onChange={this.changeName}/>
                    </div>

                    <div className="field-group">
                        <label htmlFor="location" className="fwb">My billing address</label><br/>
                        <input
                            id="location"
                            type="text"
                            value={this.state.location}
                            onChange={this.onLocationChange}
                            autoComplete="off"
                        />
                    </div>

                    {this.renderBankDetailsInputs()}

                    <div className="field-group">
                        {this.renderUploadButton()}
                    </div>

                    <div className={`agree-to-terms ${isFormValid}`}>
                        <input
                            type="checkbox"
                            value={this.state.agreedTerms}
                            onClick={() => this.setState({agreedTerms: !this.state.agreedTerms})}
                        />
                        <h3>By clicking this you agree to our
                            <a href={this.buildLinkForPdf()} target="_blank" rel="noopener noreferrer"> agreement </a>
                        </h3>
                    </div>

                    <div className="field-group">
                        <input id="name" disabled={isCheckbox} type="submit" name="Submit" onClick={() => this.submitForm(this.state)}/>
                    </div>
                </div>
            )
        }

        return <div className="loader">Loading...</div>;
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    {loggedUserData, uploadVideo, generateMuxUpload}
)(Video);