import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {galleryData, logoutUser, markConversationAsRead} from '../actions';
import Autocomplete from "react-google-autocomplete";
import {isDiscount} from "../reducers/customHelpers";
import Membership from "./pages/settings/Membership";
import {GOOGLE_API_KEY} from '../config';

class Header extends React.Component {
    state = {queryExists: "", discount: {}, popupMembership: false, showNewMessage: false, messageInfo: null};

    // Kisunas discount
    async componentDidMount() {
        let discount = await isDiscount(this.props.user.membershipStripe.pricepoint.amount);
        if (discount !== undefined) {
            this.setState({discount: discount})
        }

        // try {
        //     socket.emit("startListening", {"eventType":"unreadConversation"});
        //     socket.on("unreadConversation", (message) => {
        //         if (this.state.showNewMessage) {
        //             this.setState({showNewMessage: false}, () => {
        //                 this.setState({showNewMessage: true, messageInfo: message});
        //             });
        //         } else {
        //             this.setState({showNewMessage: true, messageInfo: message});
        //             setTimeout(() => {
        //                 this.setState({showNewMessage: false});
        //             }, 5000)
        //         }
        //     });
        // } catch (e) {
        //
        // }
    }

    closePopupMembership = () => {
        if (this.state.popupMembership) {
            this.setState({popupMembership: false})
        }
    };

    onLocationSelect = (place) => {
        let short_name = '';

        if (place.place_id) {
            place.address_components.map((address) => {
                if (address.short_name.length === 2 ) {
                    short_name = address.short_name;
                }
                return short_name;
            });

            const vp = place.geometry.viewport.toJSON();
            console.log(place.geometry.viewport, vp);

            this.props.galleryData(
                this.props.userId,
                'search',
                0,
                0,
                short_name,
                place.geometry.location.lat(),
                place.geometry.location.lng(),
                place.place_id,
                vp.north,
                vp.south,
                vp.east,
                vp.west,
                'location'
            );
        }
    };

    renderUserMenu() {
        return (
            <div className="nav-wrapper pointer-navigation">
                <i className="icon-menu" style={{fontSize: '24px'}}/>
                <div className="item-back-to-gallery-from-settings">
                    <Link to="/" style={{fontSize: '24px'}}>
                        <i className="icon-gallery" style={{fontSize: '24px'}}/>
                    </Link>
                </div>
            </div>
        )
    }

    renderConversationsMenu() {
        return (
            <div>
                {this.innerRenderDiscountTitle()}
                <div className="nav-wrapper pointer-navigation">
                    <div className="item-back-to-gallery-from-conversations">
                        <Link to="/" style={{fontSize: '24px'}}>
                            <i className="icon-gallery" style={{fontSize: '24px'}}/>
                        </Link>
                    </div>
                    <i className="icon-conversations" style={{fontSize: '24px'}}/>
                </div>
            </div>
        )
    }

    renderUnreadConversationsCount = () => {
        if (this.props.user.unreadConversationsIds && this.props.user.unreadConversationsIds.length > 0) {
            return (
                <span className="fwb unread-counter">
                    {this.props.user.unreadConversationsIds.length}
                </span>
            )
        }
    };

    // Kisunas discount
    innerRenderDiscountTitle() {
        if (this.state.discount.isDiscount) {
            return(
                <div
                    className="discount-background-login"
                    style={{cursor: 'pointer'}}
                    onClick={() => this.setState({popupMembership: true})}
                >
                    Congratulations, you just received <br/><h1>{localStorage.discount}% DISCOUNT!</h1>
                </div>
            )
        }
    }

    renderGalleryMenu() {
        return (
            <div>
                {this.innerRenderDiscountTitle()}
                <div className="nav-wrapper pointer-navigation">
                    <Link to='/profile/' style={{fontSize: '24px'}}>
                        <i className="icon-menu"/>
                    </Link>

                    <div className="search-input" style={{position: 'relative'}}>
                        <span className="icon icon-search another-grey-text fs14" style={{marginRight: '6px'}}/>
                        <div className="search-input--area">
                            <Autocomplete
                                apiKey={GOOGLE_API_KEY}
                                onPlaceSelected={this.onLocationSelect}
                                placeholder={"Search for location"}
                                options={{
                                    fields: ['address_components', 'geometry.location', 'geometry.viewport', 'place_id', 'formatted_address']
                                }}
                            />
                        </div>
                        <span className="icon icon-filter another-grey-text fs14"/>
                    </div>

                    <Link to="/conversations">
                        <i className="icon-conversations" style={{fontSize: '24px', position: 'relative'}}>
                            <div style={{display: 'inline', position: 'absolute', top: '-15px',right: '-7px'}}>
                                {this.renderUnreadConversationsCount()}
                            </div>
                        </i>
                    </Link>
                </div>
            </div>
        )
    }

    renderGalleryUserMenu() {
        return (
            <div className="nav-wrapper pointer-navigation">
                <a style={{fontSize: '24px'}} onClick={() => this.onClickGoBack()}>
                    <i className="icon-back"/>
                </a>

                <div className="item-right">
                </div>
            </div>
        )
    }

    renderProfileInnerMenu() {
        return (
            <div className="nav-wrapper pointer-navigation">
                <Link to="/profile" style={{fontSize: '24px'}}>
                    <i className="icon-back"/>
                </Link>

                <div className="item-right">
                </div>
            </div>
        )
    }

    renderInnerSettingsMenu() {
        return (
            <div className="nav-wrapper pointer-navigation">
                <Link to="/settings" style={{fontSize: '24px'}}>
                    <i className="icon-back"/>
                </Link>

                <div className="item-right">
                </div>
            </div>
        )
    }

    renderVideoMenu() {
        return (
            <div className="nav-wrapper pointer-navigation">
                <Link to="/" style={{fontSize: '24px'}}>
                    <i className="icon-back"/>
                </Link>

                <div className="item-right">
                </div>
            </div>
        )
    }

    onClickGoBack = () => {
        this.props.pathHelper.history.goBack();
    };

    renderNavigation(path) {
        if (this.props.userId) {
            if (path.includes("/conversations")) {
                if (this.state.discount.isDiscount) {
                    this.props.stateForDiscountPage()
                }
                return this.renderConversationsMenu();
            } else if (
                path.includes("/conversation/") ||
                path.includes("/edit-profile") ||
                path.includes("/onboarding-photo") ||
                path.includes("/offer=discount")
            ) {
                this.props.removeStateForDiscountPage()
                // These menus are being added from components or are being displayed without menu
                return <div></div>
            } else if (path.includes("/profile")) {
                this.props.removeStateForDiscountPage()
                return this.renderUserMenu();
            } else if (path.includes("/settings")) {
                this.props.removeStateForDiscountPage()
                return this.renderProfileInnerMenu();
            } else if (path.includes("/likes") || path.includes("/membership")) {
                this.props.removeStateForDiscountPage()
                return this.renderProfileInnerMenu();
            } else if (path.includes("/contact-support")) {
                this.props.removeStateForDiscountPage()
                return this.renderInnerSettingsMenu();
            } else if (path.includes("/gallery")) {
                this.props.removeStateForDiscountPage()
                return this.renderGalleryUserMenu();
            } else if (path.includes("/video")) {
                this.props.removeStateForDiscountPage()
                return this.renderVideoMenu();
            } else {
                if (this.state.discount.isDiscount) {
                    this.props.stateForDiscountPage();
                }
                return this.renderGalleryMenu();
            }
        }
    }

    // renderMessageNotification() {
    //     if (this.state.showNewMessage && parseInt(this.props.userId) !== this.state.messageInfo.lastMessage.sender) {
    //         let isGranted = isConversationPermissionGranted(this.props.user, this.state.messageInfo);
    //         let data = {userId: parseInt(this.props.userId), participantId: parseInt(this.state.messageInfo.id)};
    //         if (isGranted) {
    //             return (
    //                 <div className="notification-container" id="notification-container">
    //                     <div className="notification notification-info">
    //                         <Link
    //                             to={`/conversation/${this.state.messageInfo.id}`}
    //                             onClick={() => {
    //                                 this.setState({showNewMessage: null});
    //                                 this.props.markConversationAsRead(data)
    //                             }}>
    //                             <strong>New Message</strong><br/>
    //                             {this.state.messageInfo.user.name} : {this.state.messageInfo.lastMessage.content}
    //                         </Link>
    //                     </div>
    //                 </div>
    //             )
    //         } else {
    //             return (
    //                 <div className="notification-container" id="notification-container">
    //                     <div className="notification notification-info">
    //                         <div style={{cursor: 'pointer'}} onClick={() => {
    //                             this.setState({popupMembership: true})
    //                             this.props.markConversationAsRead(data)
    //                         }}>
    //                             <strong>New Message</strong><br/>
    //                             {this.state.messageInfo.user.name} : <span className="red"> New message </span>
    //                         </div>
    //                     </div>
    //                 </div>
    //             )
    //         }
    //     }
    // }

    render() {
        let path = this.props.pathHelper.location.pathname;
        return (
            <div>
                {/*{this.renderMessageNotification()}*/}
                <div className="container">
                    {this.renderNavigation(path)}
                </div>

                <Membership popupOpen={this.state.popupMembership} closePopupMembership={() => this.closePopupMembership()}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};


export default connect(
    mapStateToProps,
    { logoutUser, markConversationAsRead, galleryData }
)(Header);
