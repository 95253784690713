import axios from 'axios';
import {siteConfig} from "../config";
import {generateBrowserToken} from "../reducers/customHelpers";

let inviteTravel = axios.create({
    baseURL: siteConfig.apiURL,
    headers: {
        Authorization: (localStorage.getItem('userToken')) ? localStorage.getItem('userToken') : '',
        "Browser-ID": (localStorage.browserToken) ? localStorage.browserToken : generateBrowserToken()
    }
});

inviteTravel.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        window.location.replace('/app/login');
    } else {
        return Promise.reject(error);
    }
});

export default inviteTravel;
