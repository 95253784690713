import React from 'react';
import {connect} from "react-redux";
import {userVerificationPhotoStatus, userVerificationPhotoUpload} from "../actions";
import {Link} from "react-router-dom";

class Verification extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {photoBeingUploaded: false, uploadedFile: null, showUpload: true};
    }

    componentDidMount() {
        this._isMounted =  true;
        this.props.userVerificationPhotoStatus(this.props.user.id).then(() => {
            if (this.props.user.verificationPhoto !== undefined) {
                this.setState({showUpload: false});
            }
            this.verification = setInterval(() => this.props.userVerificationPhotoStatus(this.props.user.id).then(() => {
                if (this.props.user.verificationStatus === 'verified') {
                   window.location.replace("/app");
                }
            }), 30000);

        })
    }

    uploadPicture = event => {
        this._isMounted && this.setState({uploadedFile: event.target.files[0], photoBeingUploaded: true}, () => {
            const data = new FormData();
            data.append('photo', this.state.uploadedFile);
            this.props.userVerificationPhotoUpload(this.props.user.id, data).then(() => {
                this._isMounted && this.setState({photoBeingUploaded: false, showUpload: false});
            });
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderWaitingForUpload = () => {
        return (
            <div className="popup-area-margin">
                <div className="popup-block">
                    <div className="popup-content-wrapper popup-preferences">
                        <div className="i-am-searching-for">
                            <h1>
                                Hi, {this.props.user.name},<br/>
                                <b>Verify</b> your profile to use Invite!
                            </h1>
                        </div>
                        <div className="gallery">
                            <div className="gallery-list" style={{display: 'block', width: '50%'}}>
                                {this.renderAddPhoto()}
                            </div>
                        </div>
                        <div style={{color: '#84939c', fontSize: '16px', marginTop: '35px'}}>
                            Once you get verified you will be able to start browsing and contacting other members!
                        </div>
                        <span
                            className="conversations--unread-title"
                            style={{paddingTop: '20px', fontSize: '18px', color: "#1EB2EA"}}
                        > Waiting for upload </span>
                    </div>
                    <div style={{padding: '20px 20px'}}>
                        <Link to="/contact-support">
                            Contact Support
                        </Link>
                    </div>
                </div>
            </div>
        )
    };

    renderWaitingForVerification = () => {
        return (
            <div className="popup-area-margin">
                <div className="popup-block">
                    <div className="popup-content-wrapper popup-preferences">
                        <div className="i-am-searching-for">
                            Hi, {this.props.user.name},<br/>
                            Verify your profile to use Invite!
                        </div>
                        <div className="gallery">
                            <div className="gallery-list" style={{display: 'block', width: '50%'}}>
                                {this.renderAddPhoto()}
                            </div>
                        </div>
                        <div style={{color: '#84939c', fontSize: '16px', marginTop: '35px'}}>
                            Once you get verified you will be able to start browsing and contacting other members!
                        </div>
                        <span
                            className="conversations--unread-title"
                            style={{paddingTop: '20px', fontSize: '18px', color: "#1EB2EA"}}
                        > Waiting for approval </span>
                    </div>
                    <div style={{padding: '20px 20px'}}>
                        <Link to="/contact-support">
                            Contact Support
                        </Link>
                    </div>
                </div>
            </div>
        )
    };

    renderDeclined = () => {
        return (
            <div className="popup-area-margin">
                <div className="popup-block">
                    <div className="popup-content-wrapper popup-preferences">
                        <div className="i-am-searching-for">
                            Hi, {this.props.user.name},<br/>
                            Verify your profile to use Invite!
                        </div>
                        <div className="gallery">
                            <div className="gallery-list" style={{display: 'block', width: '50%'}}>
                                {this.renderAddPhoto()}
                            </div>
                        </div>
                        <div style={{color: '#84939c', fontSize: '16px', marginTop: '35px'}}>
                            Once you get verified you will be able to start browsing and contacting other members!
                        </div>
                        <span
                            className="conversations--unread-title"
                            style={{paddingTop: '20px', fontSize: '18px'}}
                        > Request approval failed</span>
                        <button
                            className="button-blue button-save action-save-preferences"
                            onClick={() => {
                                this.setState({showUpload: true});
                            }}
                        >
                            Retry
                        </button>
                    </div>
                    <div style={{padding: '20px 20px'}}>
                        <Link to="/contact-support">
                            Contact Support
                        </Link>
                    </div>
                </div>
            </div>
        )
    };

    renderVerified = () => {
        return (
            <div className="popup-area-margin">
                <div className="popup-block">
                    <div className="popup-content-wrapper popup-preferences">
                        <div className="i-am-searching-for">
                            Thank you, {this.props.user.name},<br/>
                            Your account was successfully approved!
                        </div>
                        <div className="gallery">
                            <div className="gallery-list" style={{display: 'block', width: '50%'}}>
                                {this.renderAddPhoto()}
                            </div>
                        </div>
                        <div style={{color: '#84939c', fontSize: '16px', marginTop: '35px'}}>
                            Now you are able to start browsing and contacting other members!
                        </div>
                        <a
                            className="button-blue button-save action-save-preferences"
                            href="/"
                        >
                            Continue!
                        </a>
                    </div>
                    <div style={{padding: '20px 20px'}}>
                        <Link to="/contact-support">
                            Contact Support
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

    renderAddPhoto = () => {
        if (!this.state.photoBeingUploaded && this.state.showUpload) {
            return (
                <div className="gallery-listItem">
                    <div className="gallery-listItem-inny">
                        <div
                            style={{background: 'white', border: '1px dashed grey',
                                borderRadius: '10px', height: '100%', width: '100%', display: 'flex',
                                justifyContent: 'center', alignItems: 'center', color: 'grey'}}
                        >
                            <label style={{cursor: 'pointer'}}>
                                <i className="icon-plane"/>
                                <span className="fwb">ADD PHOTO</span>
                                <input type="file" style={{visibility: 'hidden'}} onChange={this.uploadPicture}/>
                            </label>
                        </div>
                    </div>
                </div>
            )
        } else if (!this.state.photoBeingUploaded && this.props.user.verificationPhoto) {
            let verificationBorder = (this.props.user.verificationStatus === 'declined') ? 'indianred' : '#1EB2EA';
            return (
                <img
                    alt="Verification"
                    className="settings--info-block--img"
                    style={{border: '3px solid ' + verificationBorder}}
                    src={this.props.user.verificationPhoto}
                />
            )
        } else {
            return (
                <div className="loader">Loading...</div>
            )
        }
    };

    render() {
        if (this.props.user.verificationStatus === 'declined') {
            return this.renderDeclined();
        } else if (this.props.user.verificationStatus === 'waitingForVerification') {
            return this.renderWaitingForVerification();
        } else if (this.props.user.verificationStatus === 'waitingForUpload') {
            return this.renderWaitingForUpload();
        } else if (this.props.user.verificationStatus === 'verified') {
            return this.renderVerified();
        } else {
            return (
                <div/>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
};

export default connect(
    mapStateToProps,
    {userVerificationPhotoStatus, userVerificationPhotoUpload}
)(Verification);