import React from 'react';
import {connect} from "react-redux";
import {loggedUserData} from "../../../actions";
import inviteTravel from "../../../apis/inviteTravel";

class contactSupport extends React.Component {
    state = { email: '', content: '', sent: false };

    componentDidMount() {
        this.props.loggedUserData(this.props.userId).then(() => this.setState({email: this.props.user.email}));
    }

    onChangeEmail = event => {
        this.setState({email: event.target.value});
    }

    onChangeContent = event => {
        this.setState({content: event.target.value});
    }

    onClickSubmit = async event => {
        event.preventDefault();
        if (this.state.email && this.state.content) {
            let data = this.state;
            await inviteTravel.post('/contact/web', data).then((res) => {
                if (res.status === 201 || res.status === 200) {
                    this.setState({sent: true});
                    alert("Your message has been sent. We will get back to you shortly!");
                }
            })
        }
    }

    render() {
        let successMessage = (this.state.sent === false) ? {display: 'none'} : {display: 'block'};
        return(
            <div className="ContactForm">
                <div style={{fontSize: '22px', fontWeight: 'bold', textAlign: 'center', paddingBottom: '10px'}}>
                    Contact support
                </div>
                <form>
                    <div className="ContactForm-group">
                        <label htmlFor="emailInput">Email</label>
                        <input
                            id="emailInput"
                            name="email"
                            type="email"
                            value={this.state.email}
                            onChange={this.onChangeEmail}
                            required
                        />
                    </div>

                    <div className="ContactForm-group">
                        <label htmlFor="contentInput">Your text</label>
                        <textarea
                            name="content"
                            id="contentInput"
                            rows="10"
                            placeholder="eg. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent cursus et ligula nec luctus."
                            value={this.state.content}
                            onChange={this.onChangeContent}
                            required
                        />
                    </div>

                    <input type="submit" value="Send" onClick={this.onClickSubmit}/>
                </form>

                <div className="ContactForm-successMessage" style={successMessage}>
                    <p>Hello!</p>
                    <p>Thank you for contacting us. We will respond to your enquiry within 2 working days.</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        userId: state.login.userId
    }
};

export default connect(
    mapStateToProps,
    { loggedUserData }
)(contactSupport);