import React from 'react';
import { connect } from 'react-redux';
import PopPop from "react-poppop";
import { submitStripePayment } from '../../actions';
import {injectStripe} from 'react-stripe-elements';
import PaymentRequestForm from '../stripe/PaymentRequestForm';
import {Link} from 'react-router-dom';

class PaymentPopup extends React.Component {
    state = {loading: false, responseStatus: 0, stripeResponse: ''};

    setPaymentButtonToken = (generatedToken) => {
        this.submitStripePayment(generatedToken)
    };

    showCardElement = () => {
        if (
            (!this.state.loading && this.state.responseStatus === 0) ||
            (this.state.loading && this.state.responseStatus === 0) ||
            (this.state.loading === null && this.state.responseStatus === 400)
        ) {
            let price;
            let pricepointName;
            if (this.props.isDiscount !== undefined && this.props.isDiscount) {
                price = this.props.priceWithDiscount;
                pricepointName = "monthly.59";
            } else {
                price = this.props.user.membershipStripe.pricepoint.amount;
                pricepointName = this.props.user.membershipStripe.pricepoint.name;
            }
            let countryCode = (this.props.user.location !== null) ? this.props.user.location.countryCode : null;
            return <PaymentRequestForm
                setToken={this.setPaymentButtonToken}
                membership={this.props.user.membershipStripe.pricepoint}
                priceAmount={price}
                countryCode={countryCode}
                pricepointName={pricepointName}
            />
        } else {
            return <div/>
        }
    };

    formActions = () => {
        if (!this.state.loading && this.state.responseStatus === 0) {
            return <button className="pay-button-stripe" onClick={() => this.generateStripePayment()}>Subscribe</button>
        } else if (this.state.loading && this.state.responseStatus === 0) {
            return <div className="loader">Loading...</div>
        } else if (this.state.loading === null && this.state.responseStatus === 200) {
            // Remove discount items every time payment was successful
            localStorage.removeItem("discountValidUntil");
            localStorage.removeItem("discount");
            if (window.fbq !== undefined) {
                window.fbq('track', 'Purchase')
            }
            return (
                <div className="payment-response-success">
                    <h1 className="thank-you">Thank you, {this.props.user.name},</h1>
                    <h1>Your payment was successful</h1>
                    <h2>The page will soon reload</h2>
                </div>
            )
        } else if (this.state.loading === null && this.state.responseStatus === 400) {
            return (
                <div className="payment-response-unsuccess">
                    <button className="pay-button-stripe" onClick={() => this.generateStripePayment()}>Subscribe</button>
                    <h1>Your payment was unsuccessful</h1>
                    <h2>Responded with:<br/> {this.state.stripeResponse}</h2>
                    <div style={{padding: '20px 20px'}}>
                        <Link to="/contact-support">
                            Contact Support
                        </Link>
                    </div>
                </div>
            )
        }
    };

    render() {
        return (
            <PopPop
                open={this.props.paymentPopupOpen}
                position="centerCenter"
                closeBtn={true}
                contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                onClose={() => this.props.closePaymentPopup()}
            >
                <div className="popup-block">
                    <div className="membership-popup-title"><h1>Payment</h1></div>
                </div>
                {this.showCardElement()}
                {this.formActions()}
            </PopPop>
        );
    }

    submitStripePayment = (token) => {
        if (this.props.isDiscount) {
            this.props.submitStripePayment(this.props.user, token, this.props.discount).then((res) => {
                this.setState({loading: null, responseStatus: res});
                if (this.state.responseStatus === 200) {
                    if (window.location.pathname.includes("/offer=discount")) {
                        setTimeout(function(){
                            window.location.replace("/app")
                        }, 3500);
                    } else {
                        setTimeout(function(){
                            window.location.reload();
                        }, 3500);
                    }
                }
            })
        } else {
            this.props.submitStripePayment(this.props.user, token).then((res) => {
                this.setState({loading: null, responseStatus: res});
                if (this.state.responseStatus === 200) {
                    setTimeout(function(){
                        window.location.reload();
                    }, 3500);
                }
            })
        }
    };

    // asynchronous function to generate stripe token for user
    generateStripePayment = async () => {
        // Create request to stripe, wait till token is created, then send to actions to create payment
        await this.props.stripe.createToken({name: this.props.userId}).then((res) => {
            if (res.token) {
                this.setState({loading: true}, () => {
                    this.submitStripePayment(res.token.id);
                });
            } else {
                this.setState({loading: null, responseStatus: 400, stripeResponse: res.error.message});
            }
        });
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};


export default connect(
    mapStateToProps,
    {submitStripePayment}
)(injectStripe(PaymentPopup));