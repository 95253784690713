import React from 'react';
import {connect} from "react-redux";
import {userDataMyLikes} from "../../../actions";
import {Link} from "react-router-dom";

class MyLikes extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {isLoaded: null}
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.userDataMyLikes(this.props.userId).then(() => this._isMounted && this.setState({isLoaded: true}));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderHeartForLikedUsers(user) {
        if (user.likes.like) {
            return (
                <div className={`gallery-fav icon`}>
                    <i className="fas fa-heart" />
                </div>
            );
        }
    }

    renderMyLikes = () => {
        return this.props.myLikes.map((user) => {
            return (
                <div className="gallery-listItem" key={user.id}>
                    <div className="gallery-listItem-inny">
                        {this.renderHeartForLikedUsers(user)}
                        <Link to={`gallery/${user.id}`}>
                            <img src={user.photo} className="gallery-image" alt={user.name} />
                        </Link>

                    </div>
                </div>
            );
        });
    }

    render() {
        if (this.props.myLikes.length > 0) {
            return (
                <div className="gallery">
                    <div className="gallery-list">
                        {this.renderMyLikes()}
                    </div>
                </div>
            );
        } else if (this.props.myLikes.length === 0 && this.state.isLoaded) {
            return (
                <div>
                    You have no likes. Your liked people from the gallery will appear here!
                </div>
            )
        }

        return (
            <div className="loader">Loading...</div>
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        myLikes: state.myLikes
    }
};

export default connect(
    mapStateToProps,
    {userDataMyLikes}
)(MyLikes);