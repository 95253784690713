import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loginUser, userFacebookAuth, userProfileRegistration, userActivateRegistration } from '../../actions';
import PopPop from 'react-poppop';

import { siteConfig } from "../../config";

import { saveDiscount } from "../../reducers/customHelpers";

class loginForm extends React.Component {
    state = { registered: false, code: 12345, uid: null, popupOpenRegister: false, popupOpenLogin: false, discount: {}, formValues: null };

    componentDidMount() {
        document.body.classList.remove('has-navbar-fixed-top');
        // Adds async function to init FB
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: siteConfig.appId,
                cookie: false,
                xfbml: false,
                version: siteConfig.fbApiVersion
            });
        }.bind(this);

        // Load the SDK asynchronously
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = '//connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        if (this.props.discount > 0) {
            this.saveAndAggregateDiscount(this.props.discount);
        }
    }

    saveAndAggregateDiscount = async givenDiscount => {
        let discount = await saveDiscount(givenDiscount);
        if (discount !== undefined) {
            this.setState({ discount: discount })
        }
    };

    checkFbAuthStatus = () => {
        // LoginFacebookAction is an action from redux actions
        let loginFacebookAction = this.props.userFacebookAuth;
        window.FB.getLoginStatus(function (response) {
            if (response.status === 'connected') {
                loginFacebookAction(response.authResponse.accessToken);
            } else {
                try {
                    window.FB.login(function (r) {
                        // console.log(r);
                        if (r.status === 'connected') {
                            loginFacebookAction(r.authResponse.accessToken);
                        }
                    }, { scope: 'email,user_birthday,user_photos,user_gender' });
                } catch (error) {
                    console.log(error);
                }
            };
        });
    };

    renderError({ error, touched }) {
        if (touched && error) {
            return (
                <div className="login-error">{error}</div>
            );
        }
    }

    renderInput = ({ input, label, meta }) => {
        const className = `form-group row`;
        return (
            <div className={className}>
                <div className="col-sm-10">
                    <input {...input} className="form-control" type="email" placeholder="name@youremail.com" />
                </div>
                {this.renderError(meta)}
            </div>
        );
    };

    renderInputName = ({ input, label, meta }) => {
        const className = `form-group row`;
        return (
            <div className={className}>
                <div className="col-sm-10">
                    <input {...input} className="form-control" type="text" placeholder="Your name" />
                </div>
                {this.renderError(meta)}
            </div>
        );
    };

    renderInputPassword = ({ input, label, meta }) => {
        const className = `form-group row`;
        return (
            <div className={className}>
                <div className="col-sm-10">
                    <input {...input} className="form-control" type="password" placeholder={label} />
                </div>
                {this.renderError(meta)}
            </div>
        );
    };

    onSubmit = formValues => {
        this.props.loginUser(formValues);
    };

    onSubmitSignup = formValues => {
        this.setState({ formValues: formValues });
        this.props.userProfileRegistration(formValues).then((res) => {
            this.setState({ registered: true, uid: res.id });
        }).catch(e => {
            let err = e.response.data.error;
            switch (err) {
                case "email-exists":
                    alert("Unfortunately, this email already exists.\nTry again using different email address");
                    break;
                case "invalid-post-data":
                    alert("Invalid post data");
                    break;
                default:
                    break;
            }
        });
    };

    renderEmailLoginPopup = () => {
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} id="login-form">
                <Field name="email" component={this.renderInput} label="Email:" />
                <Field name="password" component={this.renderInputPassword} label="Password" />
                <div className="text-center">
                    <button className="login-button">Submit</button>
                </div>
            </form>
        )
    };

    renderEmailSignupPopup = () => {
        if (!this.state.registered) {
            return (
                <div>
                    <div className="popup-content-wrapper">
                        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>Register</div>
                    </div>
                    <form onSubmit={this.props.handleSubmit(this.onSubmitSignup)} id="login-form">
                        <Field name="name" component={this.renderInputName} label="Your name" />
                        <Field name="email" component={this.renderInput} label="Email:" />
                        <Field name="password" component={this.renderInputPassword} label="Password" />
                        <Field name="repeat_password" component={this.renderInputPassword} label="Repeat Password" />
                        <div className="text-center">
                            <button className="login-button">Submit</button>
                        </div>
                    </form>
                </div>
            )
        } else {
            return (
                <div>
                    <div className="popup-content-wrapper">
                        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>Check your email!</div>
                    </div>
                    <span className="tac">
                        An activation code has been sent to your email: <b>{this.state.formValues.email}</b>.<br /><br />

                        Check your email and click on activation link to finish your registration.<br />
                        <b>Note: </b> <i>If you experience any delay in receiving your activation email,
                            please check your spam or junk mail folder.</i>
                    </span>
                </div>
            )
        }
    };

    renderDiscountTitle() {
        if (this.state.discount.isDiscount) {
            return (
                <div className="discount-background-login">
                    Congratulations, you just received <br /><h1>{localStorage.discount}% DISCOUNT!</h1>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="landing-screen">
                {this.renderDiscountTitle()}
                <div className="slogan">
                    Find<br />the perfect<br /> travel friend!
                </div>

                <div className="login-form">
                    <div className="padded">
                        <div className="fb-button-appender" onClick={() => this.checkFbAuthStatus()}>
                            <span className="cta-button">
                                CONNECT WITH FACEBOOK
                            </span>
                        </div>

                        <Link
                            className="sign-in-button action-show-login"
                            onClick={() => this.setState({ popupOpenLogin: true })}
                            to="#"
                        >
                            <div className="sign-in-button--text">SIGN IN</div>
                        </Link>
                        <Link
                            className="sign-in-button action-show-login"
                            onClick={() => this.setState({ popupOpenRegister: true })}
                            to="#"
                        >
                            <div className="sign-in-button--text">SIGN UP WITH EMAIL</div>
                        </Link>

                        <PopPop
                            open={this.state.popupOpenLogin}
                            position="centerCenter"
                            closeBtn={true}
                            contentStyle={{ maxWidth: '500px', width: '100%', maxHeight: '100%' }}
                            onClose={() => this.setState({ popupOpenLogin: false })}
                        >
                            <div className="popup-area-margin">
                                <div className="popup-block">
                                    <div className="popup-content-wrapper">
                                        <div style={{ fontSize: '22px', fontWeight: 'bold' }}>Login</div>
                                    </div>
                                    {this.renderEmailLoginPopup()}
                                </div>
                            </div>
                        </PopPop>

                        <PopPop
                            open={this.state.popupOpenRegister}
                            position="centerCenter"
                            closeBtn={true}
                            contentStyle={{ maxWidth: '500px', width: '100%', maxHeight: '100%' }}
                            onClose={() => this.setState({ popupOpenRegister: false })}
                        >
                            <div className="popup-area-margin">
                                <div className="popup-block">
                                    {this.renderEmailSignupPopup()}
                                </div>
                            </div>
                        </PopPop>

                    </div>

                    <div className="footer-text">
                        By signing up, I agree to the <a href="/terms-and-conditions" target="_blank">Terms of
                            Services</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>
                    </div>
                </div>
            </div>
        );
    }
}

const validate = (formValues) => {
    const errors = {};

    if (!formValues.name) {
        errors.name = 'Name cannot be empty!';
    }

    if (!formValues.email) {
        errors.email = 'Email cannot be empty!';
    }

    if (!formValues.password) {
        errors.password = 'Password cannot be empty!';
    }

    if (formValues.password && formValues.repeat_password && formValues.password !== formValues.repeat_password) {
        errors.repeat_password = 'Passwords are not equal';
    }

    return errors;
};

const formWrapped = reduxForm({
    form: 'loginForm',
    validate: validate
})(loginForm);

export default connect(
    null,
    { loginForm, loginUser, userFacebookAuth, userProfileRegistration, userActivateRegistration }
)(formWrapped);