let localConfig;
try {
    localConfig = require('./local_config').local;
} catch (e) {
    localConfig = {};
}

// Production values
const prodConfig = {
    'environment': 'production',
    'socketURL': 'https://rtm.invite.app',
    'apiURL': window.origin + '/api_v1/',
    'appId': '397500593755474',
    'fbApiVersion': 'v18.0',
    'stripeAPIKey': 'pk_test_Vk8EbCZ8fc4Q01shF5ungGA3007qDq97VX',
};

export const siteConfig = {...prodConfig, ...localConfig};

export const GOOGLE_API_KEY = 'AIzaSyA_eMInYp8jtgAQgxYwdfi0brWGTfkyntY';