import React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import {
    fetchConversations,
    privateConversation,
    loggedUserData,
    sendPrivateMessage,
    socketNewMessage,
    privateConversationLoadMore,
    removeIgnoreConversation
} from "../../actions";
import {reduxForm} from "redux-form";
import {socket} from "../Socket";
import {isConversationPermissionGranted} from "./conversations/isConversationPermissionGranted";
import ConversationsMembershipPopup from './conversations/ConversationsMembershipPopup';

// Check when scrolled to top of page and load more conversations if chat has them

class Conversation extends React.Component {
    constructor(props) {
        super(props);
        this.messagesEnd = React.createRef();

        this._isMounted = false;
        this.state = {loaded: false, loadMore: false, scroll: null, loadingMore: false, isDropdownHidden: true, scrolledNewMsg: null, inputField: ''};
    }

    componentDidMount() {
        this._isMounted = true;

        // if user is not loaded -> load it
        if (this.props.user === undefined) {
            this.props.loggedUserData(this.props.userId);
        }

        //Loads conversation
        let data = {
            participantId: this.props.match.params.participantId,
            userId: this.props.userId
        };
        this.props.privateConversation(data).then(() => this._isMounted && this.setState({loaded: true}));

        // Socket
        try {
            socket.emit("startListening", {"eventType":"newMessage","converserId":parseInt(this.props.match.params.participantId)});
            socket.on("newMessage", (message) => {
                this.props.socketNewMessage(message);
            });   
        } catch (e) {
            
        }

        // Listener for scroll event
        window.addEventListener('scroll', this.handleScroll.bind(this));
    };

    handleScroll = (e) => {
        // If scroll is between 250 & 100 it gets set to state
        let scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        if (scrollTop <= 250 && scrollTop >= 100) {
            this._isMounted && this.setState({scroll: scrollTop});
            // Else we set the scroll to bigger number which prevents from loading
        } else {
            if (this.state.loaded && this.props.conversation.messages.length > 0) {
                this._isMounted && this.setState({scroll: 300});
            }
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // If scroll is less or equal to 250 and additional messages are not loading, begin the loading
        // Use 250 between 100 to not overload when scrolled to top
        if (this.state.scroll <= 250 && this.state.scroll !== null && this.state.loadingMore === false) {
            // set loading more to true and increase scroll to avoid double message loading
            this._isMounted && this.setState({loadingMore: true, scroll: 300}, () => {
                // check if conversation has more messages if yes, load additional messages
                if (this.props.conversation.lastMessageId) {
                    // load messages, set loading to false and increase scroll to avoid double loading
                    let data = {
                        participantId: this.props.match.params.participantId,
                        userId: this.props.userId
                    };
                    this.props.privateConversationLoadMore(data, this.props.conversation.lastMessageId).then(() => {
                        this._isMounted && this.setState({loadingMore: false, scroll: 300});
                    });
                }
            });
        }

        // Workaround. State is set to null on the initiation of the component in order
        // for scroll bottom to work successfully
        if (this.state.scroll === null) {
            this.scrollToBottom();
        }

        // New socket message has scroll property set to true
        // Check if scroll is not null (is used to scroll to bottom)
        // ScrolledNewMsg checks if ids of last scrolled message are not the same
        // If not, then on new message scrolled automatically
        let lastMessage;
        if (this.state.loaded) {
            lastMessage = this.props.conversation.messages[this.props.conversation.messages.length-1];
            if (this.state.scroll !== null && this.state.scrolledNewMsg !== lastMessage.id && lastMessage.scroll) {
                this.setState({scroll: null, scrolledNewMsg: lastMessage.id});
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll.bind(this));
        socket.emit('stopListening', {"eventType":"newMessage","converserId":parseInt(this.props.match.params.participantId)});
        this._isMounted = false;
    }

    renderMessages(conversation) {
        return conversation.messages.map((msg) => {
            if (msg.sender === this.props.user.id) {
                msg.senderPhoto = this.props.user.photo;
                return this.renderUserSender(msg);
            } else {
                msg.senderPhoto = conversation.conversation.user.photo;
                return this.renderRecipient(msg);
            }
        });
    }

    renderUserSender(msg) {
        return (
            <div key={msg.id}>
                <div className="message-row">
                    <div className="cell cell-message cell-right">
                        <div className="content-area sender-message">
                            <div className="arrow-right" />
                            <div className="content" dangerouslySetInnerHTML={{__html: msg.content}}/>
                        </div>
                    </div>
                    <div className="cell cell-user">
                        <img className="user-photo" src={msg.senderPhoto} alt="Sender"/>
                    </div>
                </div>
                <div className="message-row--ago right">{msg.timeago}</div>
            </div>
        )
    }

    renderRecipient = (msg) => {
        return (
            <div key={msg.id}>
                <div className="message-row">
                    <div className="cell cell-user">
                        <Link to={`/gallery/${this.props.userId}/${msg.sender}`}>
                            <img className="user-photo" src={msg.senderPhoto} alt="Sender"/>
                        </Link>
                    </div>
                    <div className="cell cell-message">
                        <div className="content-area recipient-message">
                            <div className="arrow-left"/>
                            <div className="content" dangerouslySetInnerHTML={{__html: msg.content}}/>
                        </div>
                    </div>
                </div>
                <div className="message-row--ago">{msg.timeago}</div>
            </div>
        )
    };

    renderError({error, touched}) {
        if (touched && error) {
            return (
                <div className="alert alert-danger">
                    <div className="header">{error}</div>
                </div>
            );
        }
    }

    handleInputEvent = (event) => {
        this.setState({inputField: event.target.value});
    };

    renderInput = () => {
        return (
            <textarea
                name="content"
                placeholder="Write a message..."
                id="message-content"
                value={this.state.inputField}
                onChange={(event) => this.handleInputEvent(event)}
            />
            // {this.renderError(meta)}
        );
    };

    onSubmit = () => {
        if (this.state.inputField === '') {
            alert('Message cannot be empty!');
        } else {
            let data = {
                participantId: this.props.match.params.participantId,
                userId: this.props.userId
            };
            let formValues = {'content': this.state.inputField};
            this.props.sendPrivateMessage(data, formValues);
            this.setState({inputField: ''});
            if (this.state.scroll !== null) {
                this.setState({scroll: null});
            }
        }
    };

    renderNavigationWorkaround() {
        let isDropdownHidden = (this.state.isDropdownHidden) ? 'hidden' : '';
        return (
            <nav id="nav" className="navigation">
                <div className="nav-wrapper pointer-navigation">
                    <Link to="/conversations" style={{fontSize: '24px'}}>
                        <i className="icon-back"/>
                    </Link>

                    <div className="item-user" style={{zIndex: 3000}}>
                        <div className="item-user--wrapper">
                            <div className="item-user--wrapper--image-area">
                                <Link to={`/gallery/${this.props.conversation.conversation.user.id}`}>
                                    <img
                                        alt="User"
                                        className="item-user--wrapper--image-area--image"
                                        src={this.props.conversation.conversation.user.photo}
                                    />
                                </Link>
                            </div>
                            <div className="item-user--wrapper--name">{this.props.conversation.conversation.user.name}</div>
                        </div>
                    </div>

                    <Link to="#" onClick={() => this._isMounted && this.setState({isDropdownHidden: !this.state.isDropdownHidden})}>
                        <i className="icon-more" style={{fontSize: '7px'}}/>
                    </Link>
                    <div className={`conversations-remove-ignore-dropdown ${isDropdownHidden}`}>
                        <button
                            className="conversations-remove-ignore-dropdown--button"
                            onClick={() => {
                                if (window.confirm("Do you really want to delete this conversation?")) {
                                    this.props.removeIgnoreConversation(this.props.userId, this.props.match.params.participantId, 'remove')
                                }
                            }}
                        >
                            Remove conversation
                        </button>
                        <button
                            className="conversations-remove-ignore-dropdown--button"
                            onClick={() => {
                                if (window.confirm("Do you really want to ignore this conversation?")) {
                                    this.props.removeIgnoreConversation(this.props.userId, this.props.match.params.participantId, 'ignore')
                                }
                            }}
                        >
                            Ignore conversation
                        </button>
                    </div>
                </div>
            </nav>
        );
    }

    scrollToBottom = () => {
        const scrollingElement = document.scrollingElement || document.documentElement || document.body;
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
    };

    render() {
        if (this.state.loaded) {
            // Returns true/false if user can see the converstion
            let userCanSeeConversation = isConversationPermissionGranted(this.props.user, this.props.conversation.conversation);
            if (userCanSeeConversation) {
                return (
                    <div>
                        {this.renderNavigationWorkaround()}
                        <div className="conversation-private-area">
                            <div className="fixed-messages-area">
                                <div className="messages-list">
                                    {this.renderMessages(this.props.conversation)}
                                </div>
                                <span style={{visibility: 'hidden'}} ref={this.messagesEnd}/>
                            </div>

                            {/*<div className="user--fixed-expander"/>*/}
                            <div className="user--fixed-bottom-area">
                                <div className="area-centered">
                                    <form className="area-centered--padded message-area" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                        {this.renderInput()}
                                        <button
                                            className="user--fixed-bottom-area--send-button"
                                        >
                                            <i className="icon-plane"/>
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return <ConversationsMembershipPopup
                    user={this.props.user}
                    conversation={this.props.conversation}
                    onCloseGoBack={true}
                    fromConversation={true}
                />
            }
        }

        return (<div className="loader">Loading...</div>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        userId: state.login.userId,
        conversation: state.conversation
    }
};

const validate = (formValues) => {
    const errors = {};

    if (!formValues.content) {
        errors.content = 'Message cannot be empty!';
    }

    return errors;
};

const formWrapped = reduxForm({
    form: 'profileMessageForm',
    validate: validate
})(Conversation);

export default connect(
    mapStateToProps,
    {
        fetchConversations,
        privateConversation,
        loggedUserData,
        sendPrivateMessage,
        socketNewMessage,
        privateConversationLoadMore,
        removeIgnoreConversation
    }
)(formWrapped);