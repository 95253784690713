import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {userDataLikes, userDataLikesRead} from "../../../actions";

class Likes extends React.Component {
    constructor(props) {
        super(props);

        this.state = {loaded: null};
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.likedMe.length > 0) {
            this._isMounted && this.setState({loaded: true});
        } else {
            this.props.userDataLikes(this.props.userId).then(() => this._isMounted && this.setState({loaded: true}));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickMarkLikeAsSeen(userId, participantId) {
        return this.props.userDataLikesRead(userId, participantId);
    }

    renderWhoLikedMe = () => {
        return this.props.likedMe.map((like) => {
            let isUnseen = (like.isNew) ? 'border-unread' : '';
            if (like.user.photos.length !== 0) {
                return (
                    <Link
                        to={`/gallery/${like.user.id}`}
                        key={like.user.id}
                        onClick={() => this.onClickMarkLikeAsSeen(this.props.userId, like.user.id)}
                    >
                        <img
                            src={like.user.photos[0].url}
                            className={`conversations--list--item--photo--img ${isUnseen}`}
                            alt="Liked me"
                        />
                    </Link>
                );
            }
            return isUnseen;
        })
    }

    renderWhoLikedMeCounter = () => {
        let unseenCount = 0;
        this.props.likedMe.map((like) => {
            if (like.isNew) {
                unseenCount++;
            }
            return unseenCount;
        });

        if (unseenCount > 0 && unseenCount <= 9) {
            return <div className="conversations--unread-title">New <span className="fwb unread-counter">{unseenCount}</span></div>
        } else if (unseenCount > 9) {
            return <div className="conversations--unread-title">New <span className="fwb unread-counter">9+</span></div>
        }
    }

    render() {
        if (this.state.loaded && this.props.likedMe.length > 0) {
            return (
                <div>
                    <div className="conversations--title">Likes</div>
                    {this.renderWhoLikedMeCounter()}
                    <div className="conversations--list--item" style={{overflowX: 'scroll'}}>
                        <div className="conversations--list--item--photo">
                            {this.renderWhoLikedMe()}
                        </div>
                    </div>
                </div>
            )
        }

        return (<div></div>);
    }
}

const mapStateToProps = state => {
    return {
        likedMe: state.likes,
    }
};

export default connect(
    mapStateToProps,
    {userDataLikes, userDataLikesRead}
)(Likes);