import React from 'react';
import {Link} from "react-router-dom";
import {Elements, StripeProvider} from "react-stripe-elements";
import PaymentPopup from "../../stripe/PaymentPopup";
import PopPop from "react-poppop";
import {isDiscount} from "../../../reducers/customHelpers";

class ConversationsMembershipPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {paymentPopupOpen: false, popupOpen: false, discount: {}};
        this._isMounted = true;
    }

    async componentDidMount() {
        if (this.props.fromConversation) {
            this.setState({popupOpen: true})
        }
        if (this._isMounted) {
            let discount = await isDiscount(this.props.user.membershipStripe.pricepoint.amount);
            if (discount !== undefined) {
                this.setState({discount: discount})
            }

            if (window.fbq !== undefined) {
                window.fbq('track', 'InitiateCheckout')
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderMembershipButtonChecksIfTrial = () => {
        let membershipStripe = this.props.user.membershipStripe.pricepoint;
        if (membershipStripe.isTrial && !this.state.discount.isDiscount) {
            return (
                <div>
                    <Link
                        className="pay-button"
                        onClick={() => this.changeStateForPopups()}
                        style={{marginBottom: '30px', textAlign: 'center'}}
                        to="#"
                    >
                        Start Your 3-day Free Trial
                    </Link>
                    <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>
                        {membershipStripe.amount} {membershipStripe.currency}/month after your 3-day trial ends.<br/>
                        Plan automatically renews monthly.<br/> You can cancel any time.
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <Link
                        className="pay-button"
                        onClick={() => this.changeStateForPopups()}
                        style={{marginBottom: '30px'}}
                        to="#"
                    >
                        {this.renderDiscountPrice(membershipStripe)}
                    </Link>
                    <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>
                        Plan automatically renews monthly.<br/> You can cancel any time.
                    </div>
                </div>
            )
        }
    };

    renderDiscountPrice(membershipStripe) {
        if (this.state.discount.isDiscount) {
            return (
                <div>
                    Subscribe
                    <span className="price-with-discount">
                        <del>{membershipStripe.amount} {membershipStripe.currency}/month</del><br/>
                        {this.state.discount.discountPrice} {membershipStripe.currency}/month
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    Subscribe
                    <span className="price">
                        {membershipStripe.amount} {membershipStripe.currency}/month
                    </span>
                </div>
            )
        }
    }

    changeStateForPopups = () => {
        if (!this.state.paymentPopupOpen) {
            this.setState({
                paymentPopupOpen: true
            });
            if (this.props.requiresClosePopup) {
                this.props.closeMembershipPopup();
            } else {
                this.setState({popupOpen: false})
            }
        }
    };

    closePaymentPopup = () => {
        if (this.state.paymentPopupOpen) {
            this.setState({paymentPopupOpen: false})
        }
    };

    onClickCloseEvent = () => {
        if (this.props.onCloseGoBack) {
            window.location.replace("/app/conversations/")
        } else {
            this.props.closeMembershipPopup()
        }
    };

    renderDiscountTitle() {
        if (this.state.discount.isDiscount) {
            return(
                <div className="discount-background">
                    Congratulations, you just received <br/><h1>{localStorage.discount}% DISCOUNT!</h1>
                </div>
            )
        }
    }

    renderPaymentPopup() {
        if (this.state.discount.isDiscount) {
            return <PaymentPopup
                paymentPopupOpen={this.state.paymentPopupOpen}
                closePaymentPopup={() => this.closePaymentPopup()}
                isDiscount={this.state.discount.isDiscount}
                discount={this.state.discount.discount}
                priceWithDiscount={this.state.discount.discountPrice}
            />
        } else {
            return <PaymentPopup paymentPopupOpen={this.state.paymentPopupOpen} closePaymentPopup={() => this.closePaymentPopup()}/>
        }
    }

    render() {
        let participant = {};
        if (this.props.conversation !== undefined) {
            participant = this.props.conversation.conversation.user
        } else {
            participant = this.props.participant
        }
        return (
            <div>
                <PopPop
                    open={(this.props.fromConversation) ? this.state.popupOpen : this.props.popupOpen}
                    position="centerCenter"
                    closeBtn={true}
                    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                    onClose={() => this.onClickCloseEvent()}
                >
                    {this.renderDiscountTitle()}
                    <div className="popup-area-margin">
                    <div className="popup-block">
                        <div className="popup-content-wrapper">
                            <div style={{fontSize: '22px', marginTop: '20px'}}>Only Travelers can</div>
                            <div style={{fontSize: '22px', fontWeight: 'bold'}}>initiate a conversation</div>
                            <div style={{padding: '30px 10px 14px 10px', display: 'flex', justifyContent:'center'}}>
                                <div>
                                    <img
                                        alt="Participant"
                                        className={`conversations-is-locked-popup-user-img`}
                                        src={this.props.user.photo}
                                    />
                                </div>
                                <div style={{lineHeight: '122px', verticalAlign: 'middle', color: '#c4d2db', fontSize: '24px'}}>
                                    <span style={{whiteSpace: 'nowrap'}}>- <i className="fas fa-ban"/> -</span>
                                </div>
                                <div style={{float: 'right'}}>
                                    <img
                                        alt="Participant"
                                        className={`conversations-is-locked-popup-user-img`}
                                        src={participant.photo}
                                    />
                                </div>
                            </div>
                            <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>
                                Upgrade to Traveler and start chatting
                                with <span style={{color: 'black'}}>{participant.name}</span> and others
                            </div>
                            <div style={{padding: '0'}}>
                                {this.renderMembershipButtonChecksIfTrial()}
                            </div>
                        </div>
                    </div>
                </div>
                </PopPop>

                <StripeProvider apiKey={this.props.user.membershipStripe.public}>
                    <Elements>
                        {this.renderPaymentPopup()}
                    </Elements>
                </StripeProvider>
            </div>
        )
    }
}

export default ConversationsMembershipPopup;