import React from 'react';
import {Link} from "react-router-dom";
import {userDataViews, userDataViewRead } from "../../../actions";
import {connect} from "react-redux";

class Views extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {loaded: null};
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.views.length > 0) {
            this._isMounted && this.setState({loaded: true});
        } else {
            this.props.userDataViews(this.props.userId).then(() => this._isMounted && this.setState({loaded: true}));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onClickViewRead(userId, participantId) {
        // TODO popup if not diamond
        return this.props.userDataViewRead(userId, participantId);
    }

    renderUsersWhoViewedMe() {
        return this.props.views.map((view, i) => {
            let isUnseen = (view.isNew) ? 'border-unread' : '';
            // Add later for traveler to blur views if not diamond
            // let isBlurred = (this.props.user.subscriptions.diamond === null) ? 'photo-blurred' : '';
            return (
                <Link
                    to={`/gallery/${view.user.id}`}
                    key={i}
                    onClick={() => this.onClickViewRead(this.props.userId, view.user.id)}
                >
                    <img
                        src={view.user.photo}
                        className={`conversations--list--item--photo--img ${isUnseen}`}
                        alt="Liked me"
                    />
                </Link>
            );
        })
    }

    renderuserDataViewsCounter() {
        let unseenCount = 0;
        this.props.views.map((view) => {
            if (view.isNew) {
                unseenCount++;
            }
            return unseenCount;
        });

        if (unseenCount > 0 && unseenCount <= 9) {
            return <div className="conversations--unread-title">New <span className="fwb unread-counter">{unseenCount}</span></div>
        } else if (unseenCount > 9) {
            return <div className="conversations--unread-title">New <span className="fwb unread-counter">9+</span></div>
        }
    }

    render() {
        if (this.state.loaded && this.props.views.length > 0) {
            return (
                <div className="conversations--header">
                    <div className="conversations--title">Views</div>
                    {this.renderuserDataViewsCounter()}
                    <div className="conversations--list--item" style={{overflowX: 'scroll'}}>
                        <div className="conversations--list--item--photo">
                            {this.renderUsersWhoViewedMe()}
                        </div>
                    </div>
                </div>
            )
        }

        return (<div></div>);
    }
}

const mapStateToProps = state => {
    return {
        views: state.views,
    }
};

export default connect(
    mapStateToProps,
    {userDataViews, userDataViewRead}
)(Views);