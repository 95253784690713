import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PopPop from 'react-poppop';
import PaymentPopup from "../../stripe/PaymentPopup"
import {Elements, StripeProvider} from 'react-stripe-elements';
import {isDiscount} from "../../../reducers/customHelpers";

class Membership extends React.Component {
    constructor(props) {
        super(props);

        this.state = {paymentPopupOpen: false, discount: {}, loading: true};
        this._isMounted = true;
    }

    async componentDidMount() {
        if (this._isMounted) {
            let discount = await isDiscount(this.props.user.membershipStripe.pricepoint.amount);
            if (discount !== undefined) {
                this.setState({discount: discount, loading: false})
            } else {
                this.setState({loading: false})
            }

            if (window.fbq !== undefined) {
                window.fbq('track', 'InitiateCheckout')
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    changeStateForPopups = () => {
        if (this.props.popupOpen && !this.state.paymentPopupOpen) {
            this.props.closePopupMembership();
            this.setState({
                paymentPopupOpen: true
            })
        }
    };

    closePaymentPopup = () => {
        if (this.state.paymentPopupOpen) {
            this.setState({paymentPopupOpen: false})
        }
    };

    renderPaymentButton = membershipStripe => {
        if (membershipStripe.isTrial && !this.state.discount.isDiscount) {
            return (
                <div>
                    <Link
                        className="pay-button"
                        onClick={() => this.changeStateForPopups()}
                        style={{marginBottom: '30px', textAlign: 'center'}}
                        to="#"
                    >
                        Start Your 3-day Free Trial
                    </Link>
                    <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>
                        {membershipStripe.amount} {membershipStripe.currency}/month after your 3-day trial ends.<br/>
                        Plan automatically renews monthly.<br/> You can cancel any time.
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <Link
                        className="pay-button"
                        onClick={() => this.changeStateForPopups()}
                        style={{marginBottom: '30px'}}
                        to="#"
                    >
                        {this.renderDiscountPrice(membershipStripe)}
                    </Link>
                    <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>
                        Plan automatically renews monthly.<br/> You can cancel any time.
                    </div>
                </div>
            )
        }
    };

    renderDiscountPrice(membershipStripe) {
        if (this.state.discount.isDiscount) {
            return (
                <div>
                    Subscribe
                    <span className="price-with-discount">
                        <del>{membershipStripe.amount} {membershipStripe.currency}/month</del><br/>
                        {this.state.discount.discountPrice} {membershipStripe.currency}/month
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    Subscribe
                    <span className="price">
                        {membershipStripe.amount} {membershipStripe.currency}/month
                    </span>
                </div>
            )
        }
    }

    renderDiscountTitle() {
        if (this.state.discount.isDiscount) {
            return(
                <div className="discount-background">
                    Congratulations, you just received <br/><h1>{localStorage.discount}% DISCOUNT!</h1>
                </div>
            )
        }
    }

    userCanBuyMembershipContent = () => {
        let membershipStripe = this.props.user.membershipStripe.pricepoint;
        return (
            <div>
                {this.renderDiscountTitle()}
                <div className="popup-block">
                    <div className="membership-popup-title"><h1>Membership</h1></div>
                    <div className="popup-content-wrapper">
                        <div style={{fontSize: '22px', fontWeight: 'bold'}}>Upgrade your status</div>
                        <div style={{padding: '50px 30px 34px 30px'}}>
                            <img src="/app/images/traveler.png" style={{width: '100%'}} alt="Membership"/></div>
                        <div style={{fontSize: '18px', fontWeight: '600', padding: '15px 0'}}>Start chatting</div>
                        <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>Upgrade to Traveler and
                            start chatting with others
                        </div>
                        <div style={{padding: '0'}}>
                            {this.renderPaymentButton(membershipStripe)}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    userHasBoughtMembershipContent = () => {
        return (
            <div className="popup-block">
                <div className="popup-content-wrapper">
                    <div style={{fontSize: '22px', fontWeight: 'bold'}}>Your status</div>
                    <div style={{padding: '50px 30px 34px 30px'}}>
                        <img src="/app/images/traveler.png" style={{width: '100%'}} alt="Membership"/></div>
                    {/*<div style={{fontSize: '18px', fontWeight: '600', padding: '15px 0'}}>You can now start !</div>*/}
                    <div style={{letterSpacing: '-0.29px', color: '#84939c', marginBottom: '30px'}}>You can now
                        start chatting with others
                    </div>
                    <div style={{padding: '0'}}/>
                </div>
            </div>
        )
    };

    determinePopupContent = () => {
        if (this.props.user.subscriptions.self !== null || this.props.user.subscriptions.both !== null) {
            return this.userHasBoughtMembershipContent()
        } else {
            return this.userCanBuyMembershipContent()
        }
    };

    renderPaymentPopup() {
        if (this.state.discount.isDiscount) {
            return <PaymentPopup
                paymentPopupOpen={this.state.paymentPopupOpen}
                closePaymentPopup={() => this.closePaymentPopup()}
                isDiscount={this.state.discount.isDiscount}
                discount={this.state.discount.discount}
                priceWithDiscount={this.state.discount.discountPrice}
            />
        } else {
            return <PaymentPopup paymentPopupOpen={this.state.paymentPopupOpen} closePaymentPopup={() => this.closePaymentPopup()}/>
        }
    }

    render() {
        return !this.state.loading ? (
            <div>
                <PopPop
                    open={this.props.popupOpen}
                    position="centerCenter"
                    closeBtn={true}
                    contentStyle={{maxWidth: '500px', width: '100%', maxHeight: '100%'}}
                    onClose={() => this.props.closePopupMembership()}
                >
                    {this.determinePopupContent()}
                </PopPop>

                <StripeProvider apiKey={this.props.user.membershipStripe.public}>
                    <Elements>
                        {this.renderPaymentPopup()}
                    </Elements>
                </StripeProvider>
            </div>
        ) : <div className="loader">Loading...</div>
    }
};

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};

export default connect(
    mapStateToProps
)(Membership);