import React from 'react';
import { connect } from 'react-redux';
import {saveDiscount} from "../../reducers/customHelpers";
import Membership from "../pages/settings/Membership";

class DiscountPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {popupMembership: false};
    }

    componentDidMount() {
        if (this.props.user.subscriptions.self === null || this.props.user.subscriptions.both === null) {
            this.setState({popupMembership: true});
            saveDiscount(this.props.discount, this.props.user.membershipStripe.pricepoint.amount)
        }
    }

    closePopupMembership = () => {
        if (this.state.popupMembership) {
            this.setState({popupMembership: false})
        }
    };

    render() {
        if (this.props.user.subscriptions.self === null || this.props.user.subscriptions.both === null) {
            return (
                <div>
                    <div className="discount-background-page">
                        Congratulations, you just received <br/><h1>{this.props.discount}% DISCOUNT!</h1>
                    </div>

                    <div className="subscriptions--info-area">
                        <div className="subscriptions--image-area">
                            <img src={"/app/images/logo.png"} alt="traveler-subscription" style={{height: '64px'}}/>
                        </div>
                        <div className="subscriptions--info-area--title"> Discount: </div>
                        <div className="subscriptions--info-area--value"> {this.props.discount}% Discount </div>
                        <br/>
                        <div className="subscriptions--info-area--title"> Valid Until: </div>
                        <div className="subscriptions--info-area--value"> {localStorage.discountValidUntil} </div>
                        <button
                            className="subscriptions--info-area--cancel-button"
                            onClick={() => window.location.replace('/app')}
                        >
                            Gallery
                        </button>
                    </div>

                    <Membership popupOpen={this.state.popupMembership} closePopupMembership={() => this.closePopupMembership()}/>
                </div>
            )
        } else {
            window.location.replace('/app')
        }
    }
}

const mapStateToProps = state => {
    return {
        userId: state.login.userId,
        user: state.user
    }
};


export default connect(
    mapStateToProps
)(DiscountPopup);