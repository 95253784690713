import React from 'react';
import {connect} from 'react-redux';
import {firstVisit} from "../../../actions";

class Visit extends React.Component {
    componentDidMount() {
        if (localStorage.browserInfoSent === "false") {
            let data = {
                referrer: document.referrer,
                landing: window.location.href
            };
            this.props.firstVisit(data).then(res => {
                if (res.status === 200) {
                    localStorage.browserInfoSent = true;
                }
            });
        }
    }

    render() {
        return <div/>
    }
}

export default connect(
    null,
    {firstVisit}
)(Visit)